import { INTL_PRESERVE } from './../actions/types';

const initialState = {
    intl: {
        intl: {
            locale: null
        }
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INTL_PRESERVE:
            return {
                ...state, 
                intl: action.intl,
            };
        default:
            return state;
    }
};