// @see: https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

import React from "react"
import { Provider } from "react-redux"
import configureStore from "./src/s4n/state/configureStore"
import { generateCartToken } from '@centarro/js-sdk'

import { ApolloProvider } from 'react-apollo'
import { client } from './src/apollo/client'

// @see: https://github.com/supasate/connected-react-router#step-3
import { ConnectedRouter } from 'connected-react-router'
import { StaticRouter } from 'react-router'
import { cartFetch, setCartToken } from './src/components/s4n/Shop/ext/redux/actions/cart'
import { userLogin } from './src/components/s4n/Shop/ext/redux/actions/user'
import { createBrowserHistory } from 'history'

// import { useIntl, injectIntl,  IntlContextConsumer } from 'gatsby-plugin-intl'
import { intlPreserve } from './src/s4n/state/actions/intl'


/*
// @see: https://gatsbyguides.com/tutorial/authenticate-users-oauth
// @see: https://www.gatsbyjs.org/tutorial/authentication-tutorial/
// Initialize a new drupalOauth client which we can use to seed the context 
// provider.
import drupalOauth from './src/components/drupal-oauth/drupalOauth'
import DrupalOauthContext from './src/components/drupal-oauth/DrupalOauthContext';
import withDrupalOauthProvider from './src/components/drupal-oauth/withDrupalOauthProvider'

const drupalOauthClient = new drupalOauth({
    drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
    client_id: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
    client_secret: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
})
*/
// import drupalOauth from './src/components/drupal-oauth/drupalOauth'
// const token = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;


// eslint-disable-next-line react/display-name,react/prop-types
const WrapWithProvider = (props) => {

    const { element } = props;

    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = configureStore();

    store.dispatch(intlPreserve());

    // const history = createBrowserHistory();
    // let history = null;
// gatsby-browser.js START
    if (`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID) {
        if (typeof localStorage !== `undefined`) {
            store.dispatch(setCartToken(localStorage.getItem('cartToken') || generateCartToken()));
            store.dispatch(cartFetch());

            // @todo: Refactor - do not pass token here it can be obtained within action too
            let token = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;
            // if (!token) {
            //     token = drupalOauth.isLoggedIn()
            // }
            if (token) {
                store.dispatch(userLogin(token));
            }
    
            // history = createBrowserHistory();
        }
    }
// gatsby-browser.js STOP

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                {/* <ConnectedRouter history={history}> */}
                    <StaticRouter>
                        {/* {(() => {
                            const Element = element;
                            return withDrupalOauthProvider(drupalOauthClient, Element)
                        })()} */}
                        {/* {withDrupalOauthProvider(drupalOauthClient, element)} */}
                        {element}
                    </StaticRouter>
                {/* </ConnectedRouter> */}
            </ApolloProvider>
        </Provider>
    );
}

export default WrapWithProvider
// export default withDrupalOauthProvider(drupalOauthClient, WrapWithProvider);