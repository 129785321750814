// @see: https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

import { createStore, applyMiddleware, compose, combineReducers } from 'redux'

// reducers
// import appReducers from './app';
import rootReducer from './reducers'; // imports from index.js

// SHOP EXT START
import thunk from 'redux-thunk';
// import { globalHistory } from "@reach/router"
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
// import shopReducers from './../../../src/components/s4n/Shop/ext/reducers'
// import { Route, Switch } from "react-router-dom";

// ssr
/*
    const createStore = (initialState) => {
        return reduxCreateStore(
            // reducers(history),
            reducers(),
            initialState,
            compose(
                applyMiddleware(
                    // routerMiddleware(history),
                    thunk
                )
            )
        );
    }
*/

// browser
/*
    const history = createBrowserHistory();

    const createStore = (initialState) => {
        return reduxCreateStore(
            reducers(history),
            reducers(),
            initialState,
            compose(
                applyMiddleware(
                    routerMiddleware(history),
                    thunk
                )
            )
        );
    }
*/    
// SHOP EXT STOP

// const rootReducer = combineReducers({
//     appReducers,
//     // shopReducers(history),
//     shopReducers,
// });

/**
 * @see: https://github.com/zalmoxisus/redux-devtools-extension
 * Thanks to composeEnhancers we can debug redux store state in dev tools!
 */
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = typeof window !== `undefined` ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : false;
const composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const isBrowser = typeof window !== 'undefined';
const history = isBrowser ? createBrowserHistory() : undefined; // not for gatsby-ssr.js
// const history = globalHistory;

export default (initialState) => {
    return createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // not for gatsby-ssr.js
                thunk
            )
        )
    );
}

/*
const reducer = (state, action) => {
  if (action.type === `INCREMENT`) {
    return Object.assign({}, state, {
      count: state.count + 1,
    })
  }
  return state
}

const initialState = { count: 0 }

const createStore = () => reduxCreateStore(reducer, initialState)
*/

// export default createStore