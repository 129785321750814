import React, { Fragment } from 'react';

// s4n
import { useIntl } from 'gatsby-plugin-intl'
import { connect } from 'react-redux'
import { cartRemoveCoupon } from './../redux/actions/cart'

const CartSummary = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const { cart } = props;
  // or get carts from store??!!
  // const {cart: { cartToken, carts }, dispatch} = props
  // const cart = carts[0]

  // @todo: implement single coupon removal, not all at once!!!
  const handleCartRemoveCoupon = async (couponCode) => {
    const { cart } = props; // cart passed as prop is already extracted!!!
    // or get carts from store??!!
    // const {cart: { cartToken, carts }, dispatch} = props
    // const cart = carts[0]

    await props.onCartRemoveCoupon(cart.id, couponCode);
  }

  return (
    <Fragment>
      <div className={`d-flex justify-content-end`}>
        <dl className={`row text-right`}>

          {/* The subtotal needs to show the sum of order item totals, which in countries where prices are tax-inclusive (such as all of EU), is equal to the total (if you don't have promotions and fees applied). */}
          {/* <dt className="col-sm-6">{ t({ id: `soft4net_shop_commerce_cart_page_summary_subtotal` }) }</dt>
          <dd className="col-sm-6">{cart.attributes.order_total.subtotal.formatted}</dd> */}

          {cart.attributes.order_total.adjustments.map(adjustment => {
// console.log(adjustment)
            let onClick = null;
            // if (`promotion` === adjustment.type) {
            //   onClick = () => handleCartRemoveCoupon(`WspaniałyDzień10`); // @todo: this removes all coupons from cart not just one, fix it
            // }

            return (
              <Fragment key={adjustment.label}>
                <dt onClick={onClick} className={`col-sm-6 adjustment-${adjustment.type}`}>{adjustment.label}</dt>
                <dd onClick={onClick} className={`col-sm-6 adjustment-${adjustment.type}`}>
                  {adjustment.amount.formatted}
                  {`tax` === adjustment.type ? ` (${adjustment?.percentage * 100}%)` : ``}
                </dd>
              </Fragment>
            )
          })}
          <dt className="col-sm-6">{ t({ id: `soft4net_shop_commerce_cart_page_summary_total` }) }</dt>
          <dd className="col-sm-6">{cart.attributes.order_total.total.formatted}</dd>
        </dl>
      </div>
    </Fragment>
  )
}

// export default CartSummary;

const mapStateToProps = state => {
  return {
    // cart: state.shopReducers.cart,
  }
};
const mapDispatchToProps = dispatch => ({
  onCartRemoveCoupon: (cartId, couponCode) => dispatch(cartRemoveCoupon(cartId, couponCode)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CartSummary);