import { result } from 'lodash';
import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'
import { jsonapiClient } from 's4n-jsonapi-client'

import { cartFetch } from '../../redux/actions/cart'

export const checkoutChangeStep = createAction('CHECKOUT_CHANGE_STEP');
export const getCheckout = createActionThunk('CHECKOUT_GET', async (cart, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'get_checkout', {
    parameters: {
      cartToken,
      cart
    },
    
    // s4n
    options: { 
        langcode: intl.locale
    },
  });
})
export const patchCheckout = createActionThunk('CHECKOUT_UPDATE', async (cart, attributes, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  const result = await jsonapiClient(process.env.REACT_APP_API_URL, 'patch_checkout', {
    parameters: {
      cartToken,
      cart,
      attributes
    },

    // s4n
    options: {
        langcode: intl.locale
    },
  })
  store.dispatch(cartFetch()); // s4n, thanks to this we get updated adjustments, for example after selecting shipping method, payment method, promotions and so on...
  return result;
})



export const postCheckout = createActionThunk('CHECKOUT_UPDATE_POST', async (cart, attributes, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'post_checkout', {
    parameters: {
      cartToken,
      cart,
      attributes
    },

    // s4n
    options: {
        langcode: intl.locale
    },
  })
})

export const getCheckoutShippingMethods = createActionThunk('CHECKOUT_GET_SHIPPING_METHODS', async (cart, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'get_checkout_shipping_methods', {
    parameters: {
      cartToken,
      cart
    },

    // s4n
    options: {
        langcode: intl.locale
    },
  });
})

export const getCheckoutPaymentApprove = createActionThunk('CHECKOUT_GET_PAYMENT_APPROVE', async (cart, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'get_checkout_payment_approve', {
    parameters: {
      cartToken,
      cart
    },

    // s4n
    options: {
        langcode: intl.locale
    },
  });
})