import React, { PureComponent } from 'react'
// import { connect } from 'react-redux'
import { globalHistory } from "@reach/router"
import { useIntl, Link } from 'gatsby-plugin-intl'


class CommerceHelper 
    // extends PureComponent 
{
//   constructor (props) {
//     super(props)
//   }

	/**
	 * We are getting variation that was putted into the cart at details page, variation selected from dropdown if there were variations available.
	 * We need to get thee variation images from because we do not store this data in cart orderItem object
	 * 
	 * @param {*} commerceProduct 
	 */
	static getProductPurchasedVariartion(commerceProduct = {}, purchasableEntity = {}) {
        // const purchasableEntityVariationId = purchasableEntity.purchased_entity.variation_id;
        const purchasableEntityVariationId = purchasableEntity.attributes.drupal_internal__variation_id;
		return commerceProduct.relationships.variations.filter(variation => variation.variation_id === purchasableEntityVariationId).shift();
	}

    static getCart(props) {
        if (typeof props.cart === `undefined`) {
            return null;
        }

        const { cart: { carts } } = props;
        const defaultCart = carts[0];

        if (typeof defaultCart === `undefined`) {
            const cart = {
                order_items: [],
            }
            return cart;
        }

        return defaultCart;
    }

    static isCartEmpty(props) {
        if (typeof props.cart === `undefined`) {
            return true;
        }

        const { cart: { carts, itemCount, cartToken } } = props

        if (itemCount === 0 && (carts.length === 0 || carts[0] === `undefined` || !(carts[0] && carts[0].relationships && carts[0].relationships.order_items.data))) {
            return true;
        }

        return false;
    }

    static getCheckoutLinkUrl(props) {
        if (typeof props.cart === `undefined`) {
            return null;
        }

        const { cart: { itemCount, cartToken } } = props;

	    const cart = this.getCart(props);

		// const path = globalHistory.location.pathname;
		const pathAndHash = globalHistory.location.pathname + globalHistory.location.hash;
        let s4n_url_return = process.env.SOFT4NET_STATIC_BASE_URL + pathAndHash;
        
        let url = null;

		switch (process.env.SOFT4NET_SHOP_CHECKOUT_TYPE) {
            case `onsite`:
                url = `/checkout/${cart.id}`;
				break;
			case `offsite`:
                url = `${process.env.REACT_APP_API_URL}/${intl.locale}/checkout/${cart.id}?cartToken=${cartToken}&s4n_url_return=${s4n_url_return}`
                break;
            case `iframe`:
                url = `/checkout-iframe/${cart.id}?cartToken=${cartToken}`
                break;
            default:
                break;
		}

		return url
    }

    static getCheckoutLink(props) {
        if (typeof props.cart === `undefined`) {
            return null;
        }

        const intl = useIntl();
        const t = intl.formatMessage;

        const { cart: { itemCount, cartToken } } = props;

        // Returns default cart, Drupal Commerce allow to have multiple carts
	    const cart = this.getCart(props);
    // console.log(cart)

		// if (itemCount <= 0) {
		// 	return Component;
        // }
        
        const label = props.label || t({ id: "soft4net_shop_cart_flyout_action_checkout" });

		// const path = globalHistory.location.pathname;
		const pathAndHash = globalHistory.location.pathname + globalHistory.location.hash;
		let s4n_url_return = process.env.SOFT4NET_STATIC_BASE_URL + pathAndHash;

        const url = this.getCheckoutLinkUrl(props);
        let Component = null;

		switch (process.env.SOFT4NET_SHOP_CHECKOUT_TYPE) {

            case `onsite`:
                Component = <Link 
                    to={url} 
                    className={`btn btn-success`} 
                >{label}</Link>
				break;
			case `offsite`:
				Component = <a 
                    // href={`${process.env.REACT_APP_API_URL}/checkout/${cart.id}?cartToken=${cartToken}`} 
                    href={url} 
					className={`btn btn-success`} 
					// target="_blank"
					// onClick={event => resetCartToken(event)} 
					>{label}</a>
                break;
            case `iframe`:
                Component = <Link 
                    // to={`/checkout-iframe/${cart.id}`}
                    to={url} 
                    className={`btn btn-success`} 
                    // onClick={event => resetCartToken(event)} 
                    >{label}</Link>
                break;
            default:
                break;
		}

		return Component
    }
    
    static isBrowser() {
        const isBrowser = typeof window !== 'undefined';
        return isBrowser;
    }

//   render () {
//     return null;
//   }
}

// const mapStateToProps = state => {
//     return {
//       cart: state.shopReducers.cart,
//       checkout: state.shopReducers.checkout,
//     }
//   };

// export default connect(mapStateToProps)(Component);

// exports = {
//     CommerceHelper: CommerceHelper
// }

export default CommerceHelper