/**
 * We use this component mainly as <Suspense />> replacer
 * @see: https://www.smooth-code.com/open-source/loadable-components/docs/fallback/
 */
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { injectIntl } from "gatsby-plugin-intl"
// import classNames from 'classnames';

const ComponentStateless = (props) => {
    const { intl } = props
    const t = intl.formatMessage;

    const size = props.size || `2rem`;
    const paddingY = props.paddingY || `5`;

    // const aClassName = classNames({
    //     's4n-preloader': true,
    //     'text-center': true,
    //     [`py-${paddingY}`]: true,
    // });

    return (
        <React.Fragment>
            <div className={`s4n-preloader w-100 text-center py-${paddingY}`}>
                <CircularProgress size={size} />
            </div>
        </React.Fragment>
    )
}

export default injectIntl(ComponentStateless);