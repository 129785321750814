import { CHECKOUT_STEP_CUSTOMER_INFORMATION } from './../../../s4n/utils/CheckoutSteps'

import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
    orderId: null,
    orderData: null,
    included: [],
    currentStep: CHECKOUT_STEP_CUSTOMER_INFORMATION
};

export default handleActions({
    '@@router/LOCATION_CHANGE': (state) => ({
        ...state,
        currentStep: CHECKOUT_STEP_CUSTOMER_INFORMATION
    }),
    
    'CHECKOUT_CHANGE_STEP': (state, { payload }) => ({
        ...state,
        currentStep: payload
    }),
    
    CHECKOUT_GET_SUCCEEDED: (state, { payload }) => {
        if (typeof payload === `undefined`) {
            return {
                ...state,
                ...INITIAL_STATE,
            }
        }

        return {
            ...state,
            orderId: payload.cart.id,
            orderData: payload.cart,
            included: payload.included,
        }
    },

    CHECKOUT_UPDATE_SUCCEEDED: (state, { payload }) => {
        return {
            ...state,
            orderData: payload.cart,
            included: payload.included,
        }
    }
}, INITIAL_STATE)
