import { handleActions } from 'redux-actions';

export default handleActions({
  ORDER_FETCH_STARTED: (state, { payload }) => {
// console.log('ORDER_FETCH_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  ORDER_FETCH_SUCCEEDED: (state, { payload }) => {
// console.log('ORDER_FETCH_SUCCEEDED: ', payload);
    const { order, included } = payload;
    return {
      ...state,
      items: order,
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_order_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  ORDER_FETCH_FAILED: (state, { payload }) => {
// console.log('ORDER_FETCH_FAILED: ', payload);
    return state;
  },
  ORDER_FETCH_ENDED: (state, { payload }) => {
// console.log('ORDER_FETCH_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },

  ORDER_ITEM_FETCH_STARTED: (state, { payload }) => {
// console.log('ORDER_ITEM_FETCH_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  ORDER_ITEM_FETCH_SUCCEEDED: (state, { payload }) => {
// console.log('ORDER_ITEM_FETCH_SUCCEEDED: ', payload);
    const { order, included } = payload;
    return {
      ...state,
      items: [order],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_order_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  ORDER_ITEM_FETCH_FAILED: (state, { payload }) => {
// console.log('ORDER_ITEM_FETCH_FAILED: ', payload);
    return state;
  },
  ORDER_ITEM_FETCH_ENDED: (state, { payload }) => {
// console.log('ORDER_ITEM_FETCH_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },

}, {
  loading: false,
  items: [],
  included: [],
  itemCount: 0,
  errorMessage: null,
})
