import { useStaticQuery, graphql } from 'gatsby'
import FragmentProductIndex from './../../../fragments/allCommerceProductDefault/product-index'

export default () => {
    return [];
    // const data = useStaticQuery(graphql`
    //     query {
    //         index: allCommerceProduct {
    //             edges {
    //                 node {
    //                     ...FragmentProductIndex
    //                 }
    //             }
    //         }
    //     }
    // `)

    // return data;
}