import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { cartRemove, cartUpdateItem, cartAddCoupon, cartRemoveCoupon } from '../../redux/actions/cart'
import PromotionCode from './promotionCode'
// import PayPalCheckout from './PayPalCheckout';
import {getMappedIncludes, getRelationshipFromMappedIncludes} from "@centarro/js-sdk";
import CartSummary from "../../blocks/CartSummary";

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import CommerceHelper from './../../../s4n/utils/commerce-helper'
import Preloader from './../../../../Preloader'
import Product from './../../../../../../model/commerce/product'
import ProductImage from './../../../../Shop/Product/image'
import ProductPrice from './../../../../Shop/Product/price'
import ProductQuantityAddToCart from './../../../../Shop/Product/Quantity'
import Breadcrumb from './../../../s4n/Breadcrumb'
import { CHECKOUT_STEP_CART } from './../../../s4n/utils/CheckoutSteps'

import loadable from '@loadable/component'
import withDrupalOauthConsumer from './../../../../../drupal-oauth/withDrupalOauthConsumer';


import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';


import { 
    getCheckout,
    patchCheckout,
    postCheckout,
} from './../../redux/actions/checkout';

import { cloneDeep as _cloneDeep } from "lodash"

import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
	formControl: {
		position: `relative`,
		top: `-7px`,
	  	// marginLeft: theme.spacing(3),
		marginLeft: `1rem !important`,
	  	minWidth: `120px !important`,
	},
}));



// @see: https://stackoverflow.com/questions/24415631/reactjs-syntheticevent-stoppropagation-only-works-with-react-events
const stopPropagation = (event) => {
    event.stopPropagation();
    event?.nativeEvent?.stopImmediatePropagation(); // @see: https://gist.github.com/ggregoire/ce7bc946212920c0a6bad8125567d001
}


function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		// thousandSeparator
		isNumericString
		// prefix="$"
		decimalScale={2}
	  />
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};


const productVariationPrice = 0.01;
/**
 * 
 * @param {*} fPrice Float
 * @returns Integer
 */
export const exchangePriceToQuantityForCustomPriceProduct = (fPrice) => {
	if (!fPrice) {
		return 0;
	}

	return parseInt(fPrice / productVariationPrice)
}
/**
 * 
 * @param {*} iQuantity Integer
 * @returns Float
 */
export const exchangeQuantityToPriceForCustomPriceProduct = (iQuantity) => {
	if (iQuantity <= 0) {
		return parseFloat(0.00);
	}

	return parseFloat(iQuantity * productVariationPrice)
}



const CartPage = (props) => {
	const intl = useIntl();
	const t = intl.formatMessage;

	const { 
		cart: { carts, included, cartToken }, dispatch, history,
		userAuthenticated: isUserAuthenticated,
	} = props;

// console.log(carts)

	if (CommerceHelper.isCartEmpty(props)) {
		return (
			<div className={`container no-items`} key={`no_items`}>
				<div className={`row`}>
					<div className={`col`}>
						<h1>{ t({ id: `soft4net_shop_commerce_cart_page_title` }) }</h1>
						<p>{ t({ id: `soft4net_shop_commerce_cart_page_list_empty` }) }</p>
					</div>
				</div>
			</div>
		)
	}
	const mappedIncludes = getMappedIncludes(included)

	// const CheckoutLink = CommerceHelper.getCheckoutLink({
	// 	...props, 
	// 	label: t({ id: `soft4net_shop_commerce_cart_page_summary_next` }),
	// })



	const classes = useStyles();
	const [orderItem2Place, setOrderItem2Place] = useState({});
	const [loading, setLoading] = useState(false);

	const [defaultValueForPrice, setDefaultValueForPrice] = useState({
		["8774110d-1357-41af-87fe-c2ba6d86cd2e"]: productVariationPrice, // Bez podatku
		["3f103393-8b26-4c49-83d2-0979c81c4dbf"]: productVariationPrice, // 5%
		["f02726c8-923c-45a0-8037-655a401cfabd"]: productVariationPrice, // 8%
		["c3559c8a-0813-4d32-8d4b-dd6b11326ea5"]: productVariationPrice, // 23%
	});

	// const onUpdateQuantity = async (e, orderItem) => {
	const onUpdateQuantity = (orderItem) => async e => {
		stopPropagation(e);

		if (loading) {
			return false;
		}

		const quantity = parseInt(e.target.value);
// console.log(`onUpdateQuantity::quantity`);
// console.log(quantity);
		if (quantity <= 0) { // quantity might result in 0 or less
			return false;
		}

		await setLoading(true);

		await dispatch(cartUpdateItem(orderItem, {quantity: quantity}));
/*
		const data = {
			is_vat_for_companies: true,
		}
		dispatch(patchCheckout(
			props.checkout.orderData, 
			{										  
				// s4n start custom order data field $commerce_order->setData($dataKey, $dataValue);
				data,
				// s4n stop custom order data field $commerce_order->setData($dataKey, $dataValue);
			}
		));
*/
		await setLoading(false);
	}

	const onZeroProductPrices = async e => {
		stopPropagation(e);

		if (loading) {
			return false;
		}

		const cartId = carts?.[0]?.id;
		const couponCode = `OFF_100_PERCENT_EACH_PRODUCT_EXCEPT_CUSTOM_PRICE`;
		const isOff100PercentEachProductExceptCustomPrice = props.selectIsOff100PercentEachProductExceptCustomPrice();
// console.log(`isOff100PercentEachProductExceptCustomPrice = `, isOff100PercentEachProductExceptCustomPrice)

		await setLoading(true);

		if (isOff100PercentEachProductExceptCustomPrice) {
			await dispatch(cartRemoveCoupon(cartId, couponCode))
		} else {
			await dispatch(cartAddCoupon(cartId, couponCode))
		}

		await setLoading(false);
	}

	const onIsVatForCompanies = async e => {
		stopPropagation(e);

		if (loading) {
			return false;
		}

		await setLoading(true);

		await dispatch(patchCheckout(
			props.checkout.orderData, 
			{										  
				// s4n start custom order data field $commerce_order->setData($dataKey, $dataValue);
				data: {
					is_vat_for_companies: !props.selectIsVatForCompanies(),
				}
				// s4n stop custom order data field $commerce_order->setData($dataKey, $dataValue);
			}
		));

		await setLoading(false);
	}



	return (
		<Fragment>
		{carts.map(cart => {
			
			const orderItemsRelationship = cart.relationships.order_items
			if (!orderItemsRelationship.data || orderItemsRelationship.data.length === 0) {
				return (
					<div className={`container no-items`} key={`no_items`}>
						<div className={`row`}>
							<div className={`col`}>
								<h1>{ t({ id: `soft4net_shop_commerce_cart_page_title` }) }</h1>
								<p>{ t({ id: `soft4net_shop_commerce_cart_page_list_empty` }) }</p>
							</div>
						</div>
					</div>
				)
			}
			
			const cartItems = getRelationshipFromMappedIncludes(cart, 'order_items', mappedIncludes)
			
			return (
				<div key={cart.id} className={`container`}>
					<div className={`row`}>
						{/* <div className={`col-sm-8`}> */}

						{/* <div className={`col-12`}>
							<Breadcrumb activeStep={CHECKOUT_STEP_CART} />
						</div> */}

						<div className={`col-12`}>
							<h1>{ t({ id: `soft4net_shop_commerce_cart_page_title` }) }</h1>
							<table className={`table`}>
								<thead>
									<tr>
										{/* <th className="image"></th> */}
										<th className="title"></th>
										<th className="quantity">
											{/* <span>{ t({ id: `soft4net_shop_commerce_cart_page_list_header_product_quantity` }) }</span> */}
										</th>
										{!props.hidePrices &&
											<React.Fragment>
												{/* <th className="tax">{ t({ id: `soft4net_shop_commerce_cart_page_list_header_product_tax` }) }</th> */}
												<th className="price">{ t({ id: `soft4net_shop_commerce_cart_page_list_header_product_price` }) }</th>
											</React.Fragment>
										}
										<th className="remove"></th>
									</tr>
								</thead>
								<tbody>
								{cartItems.map(orderItem => { // commerce_order_item--default
// console.log(orderItem);
									const purchaseEntity = getRelationshipFromMappedIncludes(orderItem, 'purchased_entity', mappedIncludes); // commerce_product_variation--default
// console.log(purchaseEntity);
									const product = getRelationshipFromMappedIncludes(purchaseEntity, 'product_id', mappedIncludes); // commerce_product--default
// console.log(product);
									// const variation = getRelationshipFromMappedIncludes(product, 'variations', mappedIncludes);
									// s4n
									const purchasableEntity = purchaseEntity;
									// const drupal_internal__product_id = product.attributes.drupal_internal__product_id;

// const commerceProduct = Product.getItemByProductId(drupal_internal__product_id, intl.locale); // pick product from GraphQL Gatsby database !!??
// console.log(commerceProduct)
// const commerceProductPurchasedVariartion = CommerceHelper.getProductPurchasedVariartion(commerceProduct, purchaseEntity);


// Replace title for package product START
// const aProductsPackage = process.env.SOFT4NET_PRODUCTS_PACKAGE.split(',').map(v => Number(v)); // [601, 602]
// const isProductPackage = aProductsPackage.includes(drupal_internal__product_id);
// const orderItemTitle = isProductPackage ? orderItem.attributes.title.split(` - `)[0] : orderItem.attributes.title;
// Replace title for package product STOP



									let canSetPlaceField = false;
									// if (product?.relationships?.field_event_type.data.id) {
									if (product?.attributes?.drupal_internal__product_id) {
// console.log(product)
// console.log(process.env.SOFT4NET_PRODUCT_DRUPAL_ID_CAN_SET_ORDER_ITEM_PLACE_FIELD.split(',').map(value => parseInt(value)))
										canSetPlaceField = process.env.SOFT4NET_PRODUCT_DRUPAL_ID_CAN_SET_ORDER_ITEM_PLACE_FIELD.split(',').map(value => parseInt(value)).includes(product.attributes.drupal_internal__product_id);
									}



									const DRUPAL_COMMERCE_TAX_RATES = {
										'pl|standard': `23%`,
										'pl|intermediate': `8%`,
										'pl|reduced': `5%`,
									}



									return (
										<tr key={orderItem.id}>
											{/* <td className="image">
												<Link to={product.attributes.path.alias}>
													<ProductImage defaultVariation={commerceProductPurchasedVariartion} />
												</Link>
											</td> */}
											<td className="title">
												{/* <Link to={product.attributes.path.alias}> */}
													{purchasableEntity.attributes.title} - {purchasableEntity.attributes.sku}
													{/* {purchasableEntity.attributes.drupal_internal__variation_id} */}
													{/* {orderItemTitle} */}
												{/* </Link> */}
											</td>
											<td className="quantity d-flex align-items-center">
												
												{/* When we use product "Dowolna cena" zamień input z ilością sztuk na pole które przeliczy podaną kwotę jako wielokorotność 0.01zł na ilość sztuk */}
												{product.id === process.env.SOFT4NET_DRUPAL_COMMERCE_PRODUCT_DEFAULT_FREE_PRICE_UUID ?
													<React.Fragment>

														{/* @see: https://material-ui.com/components/text-fields/#floating-label */}
														<TextField 
															key={`SOFT4NET_DRUPAL_COMMERCE_PRODUCT_DEFAULT_FREE_PRICE_UUID`}
															InputProps={{
																inputComponent: NumberFormatCustom,
															}}
															// type="text" 
															// type="number"
															// step="0.01"
															// step="any" 
															className="_form-control p-1" disabled={loading} 
															// defaultValue={defaultValueForPrice[purchasableEntity.id]} 
															// defaultValue={`${orderItem.attributes.quantity * 0.01}`} 
															// value={defaultValueForPrice[purchasableEntity.id]} 
															value={defaultValueForPrice[purchasableEntity.id].numberformat} 
															// value={ parseInt(orderItem.attributes.quantity) ? parseInt(orderItem.attributes.quantity) * defaultValueForPrice[purchasableEntity.id] : defaultValueForPrice[purchasableEntity.id].numberformat } 
															onChange={(event) => setDefaultValueForPrice(prevState => ({...prevState, [purchasableEntity.id]: event.target.value}))}
															placeholder={`Kwota`}
															onKeyDown={e => {
																stopPropagation(e);

																// if (e.keyCode == 13) {
																if (e.key === 'Enter') {
/*
																	// @see: https://flaviocopes.com/how-to-comma-dot-javascript/
																	let enteredPrice = e.target.value;
																	enteredPrice = enteredPrice.replace(/,/g, '.'); // if someone provided '0,32' it is now '0.32'
																	enteredPrice = parseFloat(enteredPrice).toFixed(2);
																	// enteredPrice = parseFloat(enteredPrice);
																	// enteredPrice = Number(enteredPrice).toString();
// console.log(`enteredPrice`);
// console.log(enteredPrice);
																	setDefaultValueForPrice(enteredPrice);
*/
																	if (!defaultValueForPrice[purchasableEntity.id]) {
																		return false;
																	}
// console.log(`defaultValueForPrice`);
// console.log(defaultValueForPrice[purchasableEntity.id]);
// console.log(`defaultValueForPrice.numberformat`);
// console.log(defaultValueForPrice[purchasableEntity.id].numberformat);
																	const quantity = exchangePriceToQuantityForCustomPriceProduct(defaultValueForPrice[purchasableEntity.id]);
// console.log(`onKeyDown::quantity`);
// console.log(quantity);
																	let eventCloned = _cloneDeep(e);
																	eventCloned.target.value = quantity;
																	onUpdateQuantity(orderItem)(eventCloned);
																}
															}}
// 															onChange={async e => {
// 																let enteredPrice = e.target.value;
// 																enteredPrice = enteredPrice.replace(/,/g, '.'); // if someone provided '0,32' it is now '0.32'
// 																enteredPrice = parseFloat(enteredPrice).toFixed(2);
// console.log(`enteredPrice`);
// console.log(enteredPrice);
// 																await setDefaultValueForPrice(enteredPrice);
// 																const quantity = parseInt(enteredPrice / productVariationPrice);
// console.log(`quantity`);
// console.log(quantity);
// 																let eventCloned = _cloneDeep(e);
// 																eventCloned.target.value = quantity;
// 																await onUpdateQuantity(orderItem)(eventCloned);
// 															}}
														/>

														{/* Ilość: <input className="_form-control p-1" disabled={loading} defaultValue={parseInt(orderItem.attributes.quantity)} placeholder={`Ilość`} /> */}
													</React.Fragment>
													:
													<React.Fragment>
														<Input className="_form-control p-1" disabled={loading} defaultValue={parseInt(orderItem.attributes.quantity)} placeholder={`Ilość`}
															type={`number`}
															// size={5} min={0}
															onChange={e => onUpdateQuantity(orderItem)(e)}
															// onKeyDown={(e) => { onUpdateQuantity(e, orderItem)}}
															// onKeyDown={e => {
															// 	stopPropagation(e);

															// 	if (e.keyCode == 13) {
															// 		onUpdateQuantity(orderItem)(e);
															// 	}
															// }}
														/>

														<span className="pl-2">
															{purchasableEntity.attributes.commerce_stock_always_in_stock ? 
																<React.Fragment>
																	(Nielimitowany stan)
																</React.Fragment>
																:
																<React.Fragment>
																	(Dostępny stan: {purchasableEntity.attributes.field_stock_level.available_stock})
																</React.Fragment>
															}
														</span>
													</React.Fragment>
												}

												{/* <ProductQuantityAddToCart quantity={parseInt(orderItem.attributes.quantity)} onQuantityModify={quantity => dispatch(cartUpdateItem(orderItem, {quantity: quantity}))} /> */}
												{canSetPlaceField &&
													<FormControl className={classes.formControl}>
														<InputLabel id="demo-simple-select-label">Wybierz salę</InputLabel>
														<Select
															id="demo-simple-select"
															name={orderItem.id} // uuid
															labelId="demo-simple-select-label"
															value={orderItem2Place[orderItem.id] ? orderItem2Place[orderItem.id] : ``}
															onChange={(event) => {
																stopPropagation(event);
// console.log(orderItem2Place)		
																setOrderItem2Place(orderItem2Place => ({
																	...orderItem2Place,
																	[event.target.name]: event.target.value,
																}));
																dispatch(cartUpdateItem(orderItem, {field_place: event.target.value}))
															}}
														>
															<MenuItem value={``}>- Brak -</MenuItem>
															<MenuItem value={`star_wars`}>Star Wars</MenuItem>
															<MenuItem value={`kostki`}>Kostki</MenuItem>
														</Select>
													</FormControl>
												}
											</td>
											{!props.hidePrices &&
												<React.Fragment>
													<td className="tax">
														{/* this value has no sense it is not dynamic it is just value set for variant it does not contains and resolved values - has no information purpose */}
														{/* {DRUPAL_COMMERCE_TAX_RATES[purchasableEntity.attributes.field_tax_rate]} ({purchasableEntity.attributes.field_tax_rate}) */}
													</td>
													<td className="price">
														{/* {orderItem.attributes.total_price.formatted} */}
														<ProductPrice selectedVariation={purchasableEntity.attributes} _printLabel={true} />
													</td>
												</React.Fragment>										
											}
											<td className="remove">
												<button className="" onClick={(e) => { 
													stopPropagation(e);
													e.preventDefault(); // if there's no e.preventDefault(), event bubbling up invokes wrapping Formik::onSubmit() !!!

													dispatch(cartRemove(orderItem));
												}}>
													{/* { t({ id: `soft4net_shop_commerce_cart_page_list_remove` }) } */}
													<i className="ti-close" aria-hidden="true"></i>
												</button>
											</td>
										</tr>
									)
								})}
								</tbody>
								
									<tfoot>
										{!props.hidePrices &&
											<tr className={`cart-summary`}>
												{/* <td colSpan={4}>
													<p className={`mt-3 mb-0`}>{ t({ id: `soft4net_shop_commerce_cart_page_choose_decorative_packaging` }) }</p>
													{(() => {
														const ProductRelated = loadable(() => import(`./../../../../../../components/s4n/Shop/Product/related`));
														return (
															<ProductRelated disableHeader={true} products={process.env.SOFT4NET_PRODUCTS_PACKAGE.split(',').map(productId => Product.getItemByProductId(productId, intl.locale))} limit={2} gridClassName={`col-6 col-lg-3`} />
														)
													})()}
												</td> */}
												<td colSpan={1} className={`align-middle`}>
													{!props.disablePromoCodes &&
														<PromotionCode />
													}
												</td>
												<td colSpan={4} className={``}>
													<CartSummary 
														cart={cart} 
													/>
												</td>
											</tr>
										}
										<tr>
											<td>
												<input id="is_off_100_percent_each_product_except_custom_price_uuid" className="_form-control p-1" type={`checkbox`} defaultChecked={props.selectIsOff100PercentEachProductExceptCustomPrice()} disabled={loading} onClick={onZeroProductPrices} />
												<label htmlFor="is_off_100_percent_each_product_except_custom_price_uuid" className="pl-2" disabled={loading}>Wyzeruj kwoty produktów za wyjątkiem "Cena zakupu"</label>
											</td>
										</tr>

										{/* Funkcja nie ma zastosowania gdy w Drupal Commerce podajemy ceny zawierające podatek VAT */}
										{/* <tr>
											<td>
												<input id="is_vat_for_companies" className="_form-control p-1" type={`checkbox`} defaultChecked={props.selectIsVatForCompanies()} disabled={loading} onClick={onIsVatForCompanies} />
												<label htmlFor="is_vat_for_companies" className="pl-2" disabled={loading}>Ustaw podatek dla firm (VAT 23%) dla całości zamówienia</label>
											</td>
										</tr> */}
										
									</tfoot>
								
							</table>

							{false && !props.disablePromoCodes &&
								<div className={`row pb-5`}>
									<div className={`___col-lg-6 col`}>
										<div className={`___well`}>
											<PromotionCode />
										</div>
									</div>
									{/* <div className={`col-lg-6 text-right`}>
										{isUserAuthenticated ?
											<Fragment>
												{CheckoutLink}
											</Fragment>
											:
											<Link to={`/user/login-register?destination=${CommerceHelper.getCheckoutLinkUrl(props)}`} className={`btn btn-success`}>{ t({ id: `soft4net_shop_commerce_cart_page_summary_next` }) }</Link>
										}
									</div> */}
								</div>
							}

						</div>
						{/* <div className={`col-sm-4 text-right my-4`}>
							<PayPalCheckout cart={cart} cartToken={cartToken} history={history} />
						</div> */}
					</div>
				</div>
			)
		})}
		</Fragment>
	)
};

const mapStateToProps = state => {
  return {
    cart: state.shopReducers.cart,
	checkout: state.shopReducers.checkout,

	// @todo: right now i determine this state by percentage value but i should by order data value of is_vat_for_companies
	selectIsVatForCompanies: () => {
		// should return true only if there's one tax adjustment in cart and it is 23%, not when there are many adjustments and one of many there is also 23% !!!
		
		// return state.shopReducers.cart?.carts?.[0].attributes.order_total?.adjustments.find(adjustment => adjustment.type === `tax`)?.percentage === 0.23 // WRONG!!!

		// CORRECT!!!
		return state.shopReducers.cart?.carts?.[0].attributes.order_total?.adjustments.filter(adjustment => adjustment.type === `tax`).length === 1 && 
			state.shopReducers.cart?.carts?.[0].attributes.order_total?.adjustments.find(adjustment => adjustment.type === `tax`)?.percentage === 0.23 
	},

	/**
	 * If we have promotion set as:
	 * Include the discount in the displayed unit price => adjustment of type promotion is not applied to cart object!!!
	 * 
	 * If we have promotion set as:
	 * Only show the discount on the order total summary => adjustment of type promotion is applied to cart object!!! 
	 * But in this case there';'s a problem with VAT calculation it does not discounted???
	 */
	selectIsOff100PercentEachProductExceptCustomPrice: () => {
		return state.shopReducers.cart?.carts?.[0]?.relationships?.coupons?.data?.[0]?.id === process.env.SOFT4NET_DRUPAL_COMMERCE_PROMOTION_OFF_100_PERCENT_EACH_PRODUCT_EXCEPT_CUSTOM_PRICE_UUID
	},
  }
};
// const mapDispatchToProps = dispatch => ({
//     // cartFetch: () => dispatch(cartFetch()),
//     // getCheckout: (cart) => dispatch(getCheckout(cart)),
//     onPatchCheckout: (cart, attributes) => dispatch(patchCheckout(cart, attributes)), // used in Repository
//     // postCheckout: (cart, attributes) => dispatch(postCheckout(cart, attributes)), // used in Repository
// })
export default connect(
	mapStateToProps, 
	// mapDispatchToProps
)(withDrupalOauthConsumer(CartPage));