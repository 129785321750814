import React from 'react'
import GeneralUtils from './../../../../../utils/common'
import moment from 'moment'
import { jsonapiClient } from 's4n-jsonapi-client' // for events we do not want to user redux, we want to call an API instantly
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"
import { PAYMENT_METHOD_2_ICON } from './../Repository'
import yaml from 'js-yaml';
import { 
    kebabCase as _kebabCase,
    cloneDeep as _cloneDeep,
    isEmpty as _isEmpty,
} from "lodash"
import cn from 'classnames'
import OrderDetails from './../../../AccountManagement/Commerce/Orders/Details'


export const TAXONOMY_TERM_EVENT_TYPES_ATTRACTION_UUID = `23b27781-a9c6-454c-be70-594a3e0261d8`;
export const TAXONOMY_TERM_EVENT_TYPES_PACKAGE_UUID = `8e5a923f-4102-4bb8-9269-a0a8673d4821`;
export const TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID = `c67e9142-02eb-47cb-bbed-5c2486224e4f`;
export const TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID = `e4b3e38a-6f13-4325-97cc-d70b77a7cf2a`;
export const TAXONOMY_TERM_EVENT_TYPES_RESERVATION_UUID = `452b84a7-aab2-4479-8e30-781475a6f883`;
export const TAXONOMY_TERM_EVENT_TYPES_WORKERS_COFFEE_UUID = `3bd9c2ee-a235-4b06-90cb-0b7a709cbc1e`;
export const TAXONOMY_TERM_EVENT_TYPES_GAMING_MACHINES_UUID = `3044577a-1c8a-4c0d-a21e-6fe6a3a1d25c`;
export const TAXONOMY_TERM_EVENT_TYPES_DAY_OPEN_UUID = `e9f7324d-b009-41c4-afca-5a4bf89c97b4`;
export const TAXONOMY_TERM_EVENT_TYPES_DAY_CLOSE_UUID = `49de4dbc-c8bc-4a24-935e-0672193af940`;
export const TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID = `bb84b9ed-307c-433c-ab7e-eb16916d1792`;
export const TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_RETURN_UUID = `0e3ecea1-2936-473d-aa40-9436aa97ec59`;
export const TAXONOMY_TERM_EVENT_TYPES_SHOPPING_UUID = '0a11a51f-ea53-4c1d-8f99-3ef3f5355550';
export const TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID = '83a10c6b-2617-4687-b3cc-8ebeccb08be0';
export const TAXONOMY_TERM_EVENT_TYPES_ADDITIONAL_PIZZA_ORDER_UUID = '8021b31f-3845-437c-beac-303b32da8447';
export const TAXONOMY_TERM_EVENT_TYPES_EMPLOYEE_ADVANCE_PAYMENT_UUID = 'fed7dcfd-40e2-4a3c-871f-aa3ae632a1d9';
export const TAXONOMY_TERM_EVENT_TYPES_SALE_OF_PRODUCTS = 'cf4edfdd-74e8-4e9a-b6ad-e2f12d85b46e';
export const TAXONOMY_TERM_EVENT_TYPES_NO_CASH_REGISTER_MODIFICATION_AND_ZERO_STATISTICS = 'b66df91e-f813-409a-958b-110528c3f5c2';

export const TAXONOMY_TERM_EVENT_TYPES_CASH_DEPOSIT = 'c8ef19c4-946c-4d85-b01b-46bfce44ee66';
export const TAXONOMY_TERM_EVENT_TYPES_OTHER_EXPENSES = 'b449bdd5-df53-42a3-88ee-3ed60abb5328';
// export const TAXONOMY_TERM_EVENT_TYPES_CASH_DEPOSIT = '09598a32-0ced-426b-a796-35be5d2ebacc';
// export const TAXONOMY_TERM_EVENT_TYPES_OTHER_EXPENSES = '2662b7b4-a91f-4b08-95a5-ef56c69b60a3';

export const TAXONOMY_TERM_FOR_FIELD_RELATED_EVENT_OPTIONS = [
    TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID,
    TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
    TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
    TAXONOMY_TERM_EVENT_TYPES_ADDITIONAL_PIZZA_ORDER_UUID,
    TAXONOMY_TERM_EVENT_TYPES_SALE_OF_PRODUCTS,
    TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID,
]

const EventEntity = {
    /**
     * Tylko dla wyvbranych zdarzen mam pozaywac koszyk i realizować zamowienie
     * 
     * @param {*} context 
     * @returns boolean
     */
    isAttractionOrPackage: (eventTypeUuid) => [TAXONOMY_TERM_EVENT_TYPES_ATTRACTION_UUID, TAXONOMY_TERM_EVENT_TYPES_PACKAGE_UUID].includes(eventTypeUuid),
    isDeposit: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID,
    isVoucherPurchase: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
    isReservation: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_RESERVATION_UUID,
    isWorkersCoffee: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_WORKERS_COFFEE_UUID,
    isGamingMachines: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_GAMING_MACHINES_UUID, // Automaty
    isDayOpen: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_DAY_OPEN_UUID,
    isDayClose: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_DAY_CLOSE_UUID,
    isUseOfProducts: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID,
    isDepositReturn: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_RETURN_UUID, // Zwrot / zwrot zadatku
    isShopping: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_SHOPPING_UUID,

    // isPizzaOrder: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
    isPizzaOrder: (eventTypeUuid) => [
        TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
        TAXONOMY_TERM_EVENT_TYPES_ADDITIONAL_PIZZA_ORDER_UUID,
    ].includes(eventTypeUuid),

    isEmployeeAdvancePayment: (eventTypeUuid) => eventTypeUuid === TAXONOMY_TERM_EVENT_TYPES_EMPLOYEE_ADVANCE_PAYMENT_UUID,
    isOrderable: (eventTypes, eventTypeUuid) => eventTypes.find(taxonomyTermEventTypes => taxonomyTermEventTypes.id === eventTypeUuid)?.attributes?.field_is_cash_transaction ? true : false,
    isDecreaseCashRegisterEventType: (eventTypeUuid) => [
        TAXONOMY_TERM_EVENT_TYPES_SHOPPING_UUID,
        TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
        // TAXONOMY_TERM_EVENT_TYPES_ADDITIONAL_PIZZA_ORDER_UUID,
        TAXONOMY_TERM_EVENT_TYPES_EMPLOYEE_ADVANCE_PAYMENT_UUID,
        TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_RETURN_UUID,
        TAXONOMY_TERM_EVENT_TYPES_CASH_DEPOSIT,
        TAXONOMY_TERM_EVENT_TYPES_OTHER_EXPENSES,
    ].includes(eventTypeUuid),
    canAddBookingInfoStartAndDuration: (eventTypeUuid) => [
        TAXONOMY_TERM_EVENT_TYPES_ATTRACTION_UUID,
        TAXONOMY_TERM_EVENT_TYPES_PACKAGE_UUID,
        TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID,
        // TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_RETURN_UUID,
        TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
        // TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
        // TAXONOMY_TERM_EVENT_TYPES_ADDITIONAL_PIZZA_ORDER_UUID,
        TAXONOMY_TERM_EVENT_TYPES_RESERVATION_UUID,
    ].includes(eventTypeUuid),
    canAddBookingInfoDuration: (eventTypeUuid) => ![
        TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
        TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
        TAXONOMY_TERM_EVENT_TYPES_ADDITIONAL_PIZZA_ORDER_UUID,
        // TAXONOMY_TERM_EVENT_TYPES_RESERVATION_UUID,
    ].includes(eventTypeUuid),
    shouldDisplayZeroOrderTotalNumber: (eventTypeUuid) => [
        TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID,
        TAXONOMY_TERM_EVENT_TYPES_WORKERS_COFFEE_UUID,
        TAXONOMY_TERM_EVENT_TYPES_GAMING_MACHINES_UUID,
        TAXONOMY_TERM_EVENT_TYPES_NO_CASH_REGISTER_MODIFICATION_AND_ZERO_STATISTICS,
    ].includes(eventTypeUuid),

    isRelatedEventAllowed: (eventTypeUuid) => TAXONOMY_TERM_FOR_FIELD_RELATED_EVENT_OPTIONS.includes(eventTypeUuid),

    /**
     * calculate Duration From Midnight as minutes
     * 
     * @param Date newValue
     * @returns integer Duration in minutes
     */
     calculateDurationFromMidnight: (newValue) => {
// console.log(newValue.constructor.name) // === Date
        if (!newValue) {
            return 0;
        }

        const newValueSecondsZeroed = new Date(newValue?.getTime()); // clone Date object
        newValueSecondsZeroed.setSeconds(0);
        newValueSecondsZeroed.setMilliseconds(0);
// console.log(`newValueSecondsZeroed = `, newValueSecondsZeroed);
        const now = moment(newValueSecondsZeroed); // without seconds because they are zeroed
        const then = moment(newValueSecondsZeroed).startOf('day');
        const momentDuration = moment.duration(now.diff(then));
        
        // const durationInMinutes = momentDuration.minutes();
        const durationInMinutes = momentDuration.asMinutes();
        const iDurationInMinutes = parseInt(durationInMinutes);
// console.log(`newValue: `, newValue);
// console.log(`durationInMinutes: `, durationInMinutes);
// console.log(`iDurationInMinutes: `, iDurationInMinutes);
        return iDurationInMinutes;
    },

    isCloseEventNotAddedOnTime: (entity) => {
// console.log(entity)
        const created = entity?.attributes.created;
        if (moment(created).unix() === moment(created).endOf('day').unix()) { // because when CLOSE event was not added on time we add CLOSE event as "end of day"
            return true;
        }

        return false;
    },

    getTableRow: (item, index, context) => {
        const { intl, mappedIncludes, EventActions } = context;
// console.log(item);
// return null;
// if (66 === item.attributes.drupal_internal__nid) {
//     console.log(mappedIncludes)
//     console.log(item)
// }

        const relationshipUser = getRelationshipFromMappedIncludes(item, `uid`, mappedIncludes); // user--user
        const relationshipRevisionUser = getRelationshipFromMappedIncludes(item, `revision_uid`, mappedIncludes); // user--user
        const relationshipFieldEventType = getRelationshipFromMappedIncludes(item, `field_event_type`, mappedIncludes); // taxonomy_term--event_types
        const relationshipFieldOrder = getRelationshipFromMappedIncludes(item, `field_order`, mappedIncludes); // commerce_order--default
        // const relationshipFieldEvent = getRelationshipFromMappedIncludes(item, `field_event`, mappedIncludes); // node--event

        if (!relationshipFieldEventType) {
            return null;
        }

// console.log(relationshipRevisionUser)
// console.log(relationshipFieldEvent)
        
        if (
            !relationshipUser 
            // || !relationshipFieldOrder
        ) {
            return null;   
        }
        
        const uuid = item.id;
        const id = item.attributes.drupal_internal__nid;

// console.log(relationshipFieldEventType)
        let isCloseEventNotAddedOnTime = false;
        if (EventEntity.isDayClose(relationshipFieldEventType?.id)) {
            isCloseEventNotAddedOnTime = EventEntity.isCloseEventNotAddedOnTime(item);
        }

        const isModified = null !== item.attributes.revision_log;
        const modifyDescription = isModified ? `
            Zmodyfikowane przez użytkownika: ${relationshipRevisionUser?.attributes?.display_name}
            Czasu: ${GeneralUtils.dateFormat(item.attributes.revision_timestamp, `LLL`, intl.locale)}
            Opis modyfikacji: "${item.attributes.revision_log}"
        ` : null;

        const hasDescription = !!item?.attributes?.body?.processed;

        const row = {
            uuid: item.id, // for json interaction
            type: item.type, // for json interaction

            id,
            ['field_event_type.id']: relationshipFieldEventType.id,
            ['created']: EventEntity.getCreatedAt(item),
            // ['uid.name']: EventEntity.getUserName(item, { intl, mappedIncludes, relationshipUser }),
            ['user_name']: EventEntity.getUserName(item, { intl, mappedIncludes, relationshipUser }),
            ['field_booking_info.value']: EventEntity.getFieldBookingInfo(item, { intl }),
            [
                // 'field_order.order_total.total.number'
                'field_order.total_price.number'
                
            ]: EventEntity.getOrderTotalNumberDisplayPurposes(item, { intl, mappedIncludes }),
            ['field_payment_method']: EventEntity.getPaymentGatewayFormatted(item, { intl }),
            ['field_sales_document']: EventEntity.getSalesDocumentFormatted(item, { intl }),
            ['field_order.order_total.adjustments.0.percentage']: EventEntity.getTaxRate(item, { intl, mappedIncludes }),
            ['field_order.order_items.quantity']: EventEntity.getOrderItemsQuantityAccumulated(item, { mappedIncludes }),
            ['body.value']: (hasDescription && <div dangerouslySetInnerHTML={{__html: item.attributes.body.processed}} />),
            /**
             * field_order.order_items.purchased_entity.id - filters for "commerce_product_variation--default"
             * field_order.order_items.purchased_entity.product_id.id - filters for "commerce_product--default"
             */
            ['field_order.order_items.purchased_entity.id']: <OrderDetails order={relationshipFieldOrder} mappedIncludes={mappedIncludes} tableCellDisplay={true} />,
            ['actions']: <EventActions 
                uuid={uuid} 
                nid={item.attributes.drupal_internal__nid} 
                isModified={isModified} 
                userName={EventEntity.getUserName(item, { intl, mappedIncludes, relationshipUser })} 
                userColor={relationshipUser.attributes.field_color} 
                hasDescription={hasDescription}
            />, 

            raw: {
                payment_gateway: EventEntity.getPaymentGateway(item), // needed for today/selected calculation in src/components/s4n/AccountManagement/Events/index.js
                sales_document: EventEntity.getSalesDocument(item), // needed for today/selected calculation in src/components/s4n/AccountManagement/Events/index.js
                // mappedIncludes,
                // field_order: relationshipFieldOrder,
            },

            // metadata columns
            className: cn({
                [_kebabCase(relationshipFieldEventType.attributes.name)]: true,
                ['event-modified']: isModified,
                ['close-event-not-added-on-time']: isCloseEventNotAddedOnTime,
            }),
            styleColor: relationshipFieldEventType.attributes.field_color ? GeneralUtils.shadeColor(relationshipFieldEventType.attributes.field_color, -40) : null, // lighten color
            tooltip: isModified ? {
                'data-toggle': 'tooltip',
                'data-placement': 'bottom',
                // title: modifyDescription, // bootstrap 4 tooltip
                'data-original-title': modifyDescription, // bootstrap 4 tooltip
            } : null,
            // popover: isModified ? {
            //     'data-toggle': 'popover',
            //     'data-placement': 'bottom',
            //     'data-content': modifyDescription, // bootstrap 4 tooltip
            //     'data-container': 'body',
            // } : null,

            // item: item,
            // subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined, // If subRows were detect on the original data object, this will be an array of those materialized row objects.
            // subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
        }

        return row;
    },

    /**
     * Przygotowanie/przetworzenie danych przed zapisem do JSON:API
     * 
     * @param {*} context 
     * @returns 
     */
     prepareData: (values, context) => {
        const { props } = context;

        const allValuesWithEventField = _cloneDeep(values);
// console.log(`handleSubmitFormAddEvent::allValuesWithEventField: `, allValuesWithEventField)
        const eventTypeUuid = allValuesWithEventField?.event_type || null;

        const isOrderableEvent = EventEntity.isOrderable(props?.event?.event_types?.data, eventTypeUuid);
        const isAttractionOrPackage = EventEntity.isAttractionOrPackage(eventTypeUuid);
        const isDeposit = EventEntity.isDeposit(eventTypeUuid);
        const isReservation = EventEntity.isReservation(eventTypeUuid);
        const isDayClose = EventEntity.isDayClose(eventTypeUuid);
        const isDepositReturn = EventEntity.isDepositReturn(eventTypeUuid);

        const isDecreaseCashRegisterEventType = EventEntity.isDecreaseCashRegisterEventType(eventTypeUuid);
        const canAddBookingInfoStartAndDuration = EventEntity.canAddBookingInfoStartAndDuration(eventTypeUuid);
        const canAddBookingInfoDuration = EventEntity.canAddBookingInfoDuration(eventTypeUuid);
        const shouldDisplayZeroOrderTotalNumber = EventEntity.shouldDisplayZeroOrderTotalNumber(eventTypeUuid);

        const relationshipFieldEventType = props?.selectEventTypeByUuid(eventTypeUuid);

        let attributes = {
            title: relationshipFieldEventType?.attributes.name,
        }

        if (isDayClose && !props.selectYesterdayEventsHasCloseEvent()) { // close event was not added last time for day that was opened so add it now with past created date for the day when it was opened
            const lastOpenDayEvent = props.selectLastOpenDayEvent();

            attributes = {
                ...attributes,
                created: moment(lastOpenDayEvent?.[0]?.attributes?.created).endOf('day').format(),
            }
        }

        /**
         * For isDepositReturn we do not create an order but we fill payment_method "Gotówka" aby stan kasy był odliczany i aby potem na liście mozna bylo pokazac wartosc zwrotu zadatku jako kwopte ujemna
         */
        if (isOrderableEvent || isDepositReturn) {

            let payment_method_group_yaml_prepared = {};
            allValuesWithEventField.payment_method_group.forEach(oPaymentMethod => {
                const paymentMethodKey = Object.keys(oPaymentMethod)[0];
                if (`` !== oPaymentMethod[paymentMethodKey]) {
                    if (shouldDisplayZeroOrderTotalNumber) {
                        payment_method_group_yaml_prepared[paymentMethodKey] = 0; // change here not in CMS!!!
                    } else {
                        // ! @todo watch this code this "minus" loginc is in both places also in EventEntity.getOrderTotalNumberDisplayPurposes()
                        payment_method_group_yaml_prepared[paymentMethodKey] = isDecreaseCashRegisterEventType ? -oPaymentMethod[paymentMethodKey] : oPaymentMethod[paymentMethodKey]; // we change here not in CMS, this is main, don't know wether good or bad one, assumption of an app!!!
                    }
                }
            });
// console.log(`payment_method_group_yaml_prepared: `, payment_method_group_yaml_prepared)

            let sales_document_group_yaml_prepared = {};
            allValuesWithEventField.sales_document_group.forEach(oSaleDocument => {
                const saleDocumentKey = Object.keys(oSaleDocument)[0];
                if (`` !== oSaleDocument[saleDocumentKey]) {
                    sales_document_group_yaml_prepared[saleDocumentKey] = oSaleDocument[saleDocumentKey];
                }
            });
// console.log(`sales_document_group_yaml_prepared: `, sales_document_group_yaml_prepared)

            attributes = {
                ...attributes,
                // field_payment_method: allValuesWithEventField.payment_method_group, // [{}, {}, {}]
                // field_payment_method: payment_method_group_yaml_prepared, // {payment1: value1, payment2: value2, payment3: value3,}
                field_payment_method: _isEmpty(payment_method_group_yaml_prepared) ? `` : yaml.dump(payment_method_group_yaml_prepared),
                field_sales_document: _isEmpty(sales_document_group_yaml_prepared) ? `` : yaml.dump(sales_document_group_yaml_prepared),
            }

            if (canAddBookingInfoStartAndDuration) {
                const bookingInfoDuration = canAddBookingInfoDuration ? EventEntity.calculateDurationFromMidnight(allValuesWithEventField.booking_info_duration) : null;

                attributes = {
                    ...attributes,
                    field_booking_info: {
                        value: allValuesWithEventField.booking_info_start ? moment(allValuesWithEventField.booking_info_start).format() : null,
                        end_value: allValuesWithEventField.booking_info_start ? moment(allValuesWithEventField.booking_info_start).add(bookingInfoDuration, 'm').format() : null,
                        duration: bookingInfoDuration,
                        rrule: null,
                        rrule_index: null,
                        timezone: ``,
                    },
                }
            }
        }

        let relationships = {
            field_event_type: {
                data: {
                    type: `taxonomy_term--event_types`,
                    id: eventTypeUuid,
                },
            },
            // field_order: null, // referencja
        }

/*
        field_event: {
            data: [
                {
                    type: "node--event",
                    id: "3fdf011f-49dc-4536-b0f8-3bd46f86cda4"
                },
                {
                    type: "node--event",
                    id: "ba19683a-d314-4db1-836d-a11b05771fb4"
                }
            ],
        },
*/
        /**
         * @todo implement method setBidirectionalRelationship()
         * field_event moze zaiwerac eventy "slave" jak zapisujemy [Atrakcja, Pakiet] lub "master" jak zapisujemy [Zakup vouchera, Zamowienie pizzy, Sprzedaż produktów, Wykorzystanie produktów, Zadatek]
         */
         const isRelationshipSlaveEvent = EventEntity.isRelatedEventAllowed(eventTypeUuid);
         if (!isRelationshipSlaveEvent && allValuesWithEventField.field_event) { // this does not remove with empty array as it should!!!
        // if (allValuesWithEventField.field_event?.length) { // this does not remove with empty array as it should!!!
            relationships = {
                ...relationships,
                field_event: {
                    // data: { // in Drupal this field has single value set
                    //     type: `node--event`,
                    //     id: allValuesWithEventField.field_event,
                    // },
                    data: allValuesWithEventField.field_event.length ? 
                        allValuesWithEventField.field_event.map(eventUuid => ({ // in Drupal this field has multiple values set
                            type: `node--event`,
                            id: eventUuid,
                        }))
                        :
                        [] // if all relations are removed store an empty array => clear all relationships !!!
                },
            }
        }

        let eventData = {
            // type: `node--event`, // "node--event"
            ...(allValuesWithEventField?.id !== `` ? {id: allValuesWithEventField.id} : {}), // in edit mode, PATCH request
            relationships: {
                ...relationships,
            },
            // custom: {
            //     uuid: null,
            // },
        }

        if (allValuesWithEventField?.revision_log) { // edit event
            eventData = {
                ...eventData,
   
                attributes: {                   
                    // New revision is created only if we provide a description!!!
                    // ...(allValuesWithEventField?.revision_log !== `` ? {revision: 1} : {}), // in edit mode, PATCH request
                    ...(allValuesWithEventField?.revision_log !== `` ? {revision_log: allValuesWithEventField.revision_log} : {}), // in edit mode, PATCH request
                },
            }
        } else { // add event
            eventData = {
                ...eventData,
   
                attributes: {
                    body: {
                        value: allValuesWithEventField.event_description,
                        format: `full_html`,
                    },
                       
                    ...attributes,
                },
            }
        }

        return eventData;
    },

    formPopulate: async (eventData, context) => {
        const { mappedIncludes, authorization, intl } = context;
// console.log(`formPopulate`); return;
// console.log(`eventData = `, eventData); return;
        const { event: entity, included } = eventData;
// console.log(`entity = `, entity); return;

        const eventTypeUuid = entity?.relationships?.field_event_type?.data?.id || null;

        const paymentMethod = EventEntity.getPaymentGateway(entity) || {};
        const salesDocument = EventEntity.getSalesDocument(entity) || {};

        const isRelationshipSlaveEvent = EventEntity.isRelatedEventAllowed(eventTypeUuid);
        let related = isRelationshipSlaveEvent ? await EventEntity.getRelatedEventsForSlaveEvent(entity, { authorization, intl }) : EventEntity.getRelatedEvents(entity, { mappedIncludes })
        let field_event_init = related?.map(event => event?.id)
        // let field_event_init = EventEntity.getRelatedEvents(entity, { mappedIncludes })?.map(event => event?.id)
        const field_event = [
            ...(field_event_init ? [...field_event_init] : []),
        ]

        const formPupulate = {
            id: entity.id,
            created: moment(entity.attributes.created).toDate(),
            event_type: eventTypeUuid,
            event_description: entity.attributes.body?.value,
            booking_info_start: entity.attributes?.field_booking_info?.value ? moment(entity.attributes.field_booking_info.value).toDate() : ``,
            booking_info_duration: entity.attributes?.field_booking_info?.duration ? moment().startOf('day').add(entity.attributes.field_booking_info.duration, 'm').toDate() : ``, // because duration we calculate from the midnight
            // commerce_product_variation: ``,
            payment_method_group: Object.keys(paymentMethod).map(k => ({[k]: paymentMethod[k]})),
            sales_document_group: Object.keys(salesDocument).map(k => ({[k]: salesDocument[k]})),
            // field_event: [], // Składowanie powiązanego zdarzenia typu zadatek
            field_event_init_abstract: field_event, // stores initial values for field event we need this to calculate elements to remove in EventEntity.createSlaveToMasterEventRelationship()
            field_event,
        }
// console.log(`formPupulate = `, formPupulate); return;
        return formPupulate;
    },

    /**
     * Find a decent currency library, or port commerceguys/intl to JavaScript. 😱   
     * 
     * @param {*} currencyCode 
     * @param {*} number 
     * @param {*} locale 
     * @returns 
     */
     formatPrice: (currencyCode, number, locale = null) => {
        const currencySymbols = {
            USD: '$',
            GBP: '£',
            CAD: 'CA$',
            EUR: '€',
            PLN: 'zł',
        };
        
        const currencyFormat = {
            USD: '¤#',
            GBP: '#¤',
            CAD: '¤#',
            EUR: '# ¤',
            PLN: '# ¤',
        };

        // const options = {
        //     style: 'currency',
        //     currency: currencyCode,
        //     // maximumSignificantDigits: 2,
        // }
        // return number.toLocaleString(locale, options);
    
        let formatted = currencyFormat[currencyCode].replace('¤', currencySymbols[currencyCode]);
        formatted = formatted.replace('#', parseFloat(number).toFixed(2).toLocaleString(locale));

        return formatted;
    },

    /**
     * Tylko dla wyvbranych zdarzen mam pozaywac koszyk i realizować zamowienie
     * 
     * @param {*} context 
     * @returns 
     */
    /*
        field_booking_info: {
            value: "2021-03-25T11:00:00+00:00",
            end_value: "2021-03-25T12:00:00+00:00",
            duration: 60,
            rrule: null,
            rrule_index: null,
            timezone: ""
        }
    */
    getFieldBookingInfo: (entity, context) => {
        const { intl } = context;

        let duration = ``;
        if (entity.attributes?.field_booking_info) {
            const now = moment(entity.attributes.field_booking_info.end_value);
            const then = moment(entity.attributes.field_booking_info.value);
            duration = entity.attributes.field_booking_info.duration || moment.duration(now.diff(then));
        }
        const field_booking_info = entity.attributes?.field_booking_info ? `
            ${GeneralUtils.dateFormat(entity.attributes.field_booking_info.value, `DD.MM.YYYY HH:mm`, intl.locale)} - ${GeneralUtils.dateFormat(entity.attributes.field_booking_info.end_value, `DD.MM.YYYY HH:mm`, intl.locale)}   (${GeneralUtils.duration(duration, null, intl.locale)} h)
        ` : `-`;

        return field_booking_info;
    },

    /**
     * With this it's impossible to have splited payment for different payment methods
     * payment_gateway: relationshipFieldOrderPaymentGateway ? relationshipFieldOrderPaymentGateway.attributes.label : `-`,
     * Replacing with custom field_payment_method in YAML format
     * 
     * @param {*} entity 
     * @param {*} context 
     * @returns 
     */
    getPaymentGateway: (entity, context = null) => {
        let field_payment_method = null;

        try {
            field_payment_method = entity.attributes.field_payment_method ? yaml.load(entity.attributes.field_payment_method, 'utf8') : null;
        } catch (error) {
            console.log(error);
        }

        return field_payment_method;
    },

    /**
     * With this it's impossible to have splited payment for different payment methods
     * payment_gateway: relationshipFieldOrderPaymentGateway ? relationshipFieldOrderPaymentGateway.attributes.label : `-`,
     * Replacing with custom field_payment_method in YAML format
     * 
     * @param {*} entity 
     * @param {*} context 
     * @returns 
     */
     getPaymentGatewayFormatted: (entity, context) => {
        const { intl } = context;
        const t = intl.formatMessage;

        const field_payment_method = EventEntity.getPaymentGateway(entity);

        return (
            <React.Fragment>
                {/* <div style={{whiteSpace: `break-spaces`}}> */}
                    {field_payment_method && 
                        <table className="inner">
                            {Object.keys(field_payment_method).map(payment_method => (
                                <React.Fragment>
                                    <tr>
                                        <td>{PAYMENT_METHOD_2_ICON[payment_method]}  {t({ id: `form_event_attributes_payment_method_${payment_method}`})}:</td>
                                        <td>{EventEntity.formatPrice(`PLN`, field_payment_method[payment_method])}</td>
                                    </tr>
                                    {/* {"\n"} */}
                                </React.Fragment>
                            ))}
                        </table>
                    }
                {/* </div> */}
            </React.Fragment>
        )
    },

    /**
     * With this it's impossible to have splited payment for different payment methods
     * payment_gateway: relationshipFieldOrderPaymentGateway ? relationshipFieldOrderPaymentGateway.attributes.label : `-`,
     * Replacing with custom field_payment_method in YAML format
     * 
     * @param {*} entity 
     * @param {*} context 
     * @returns 
     */
     getSalesDocument: (entity, context = null) => {
        let field_sales_document = null;

        try {
            field_sales_document = entity.attributes.field_sales_document ? yaml.load(entity.attributes.field_sales_document, 'utf8') : null;
        } catch (error) {
            console.log(error);
        }

        return field_sales_document;
    },

    getSalesDocumentFormatted: (entity, context) => {
        const { intl } = context;
        const t = intl.formatMessage;

        const field_sales_document = EventEntity.getSalesDocument(entity);

        return (
            <React.Fragment>
                {/* <div style={{whiteSpace: `break-spaces`}}> */}
                    {field_sales_document && 
                        <table className="inner">
                            {Object.keys(field_sales_document).map(sale_document => (
                                <React.Fragment>
                                    <tr>
                                        <td>{t({ id: `form_event_attributes_sales_document_${sale_document}`})}:</td>
                                        <td>{field_sales_document[sale_document]}</td>
                                    </tr>
                                    {/* {"\n"} */}
                                </React.Fragment>
                            ))}
                        </table>
                    }
                {/* </div> */}
            </React.Fragment>
        )
    },

    getAttribute: (attribute_name) => {
        return attribute_name;
    },

    getOrder: (entity, context) => {
        const { mappedIncludes } = context;
        const relationshipFieldOrder = getRelationshipFromMappedIncludes(entity, `field_order`, mappedIncludes); // commerce_order--default
        
        return relationshipFieldOrder || null;
    },

    getOrderItems: (entity, context) => {
        const { mappedIncludes } = context;
        const relationshipFieldOrder = EventEntity.getOrder(entity, context); // commerce_order--default
        const relationshipFieldOrderRelationshipOrderItems = getRelationshipFromMappedIncludes(relationshipFieldOrder, 'order_items', mappedIncludes);
        
        return relationshipFieldOrderRelationshipOrderItems || null;
    },

    getOrderItemsPurchasedEntity: (entity, context) => {
        const { mappedIncludes } = context;
        const relationshipFieldOrderRelationshipOrderItems = EventEntity.getOrderItems(entity, context); // commerce_order_item--default

        let collection = [];
        relationshipFieldOrderRelationshipOrderItems?.forEach(order_item => {
            const purchased_entity = getRelationshipFromMappedIncludes(order_item, 'purchased_entity', mappedIncludes);
            collection.push(purchased_entity);
        })
        
        return collection;
    },

    getOrderItemsQuantityAccumulated: (entity, context) => {
        const order_items = EventEntity.getOrderItems(entity, context);
        const order_items_count = order_items ? order_items.reduce((accumulator, item) => {
// console.log(item)
            return accumulator + parseInt(item.attributes.quantity);
        }, 0) : null;
        
        return order_items_count;
    },

    getOrderTotalObject: (entity, context) => {
        const relationshipFieldOrder = EventEntity.getOrder(entity, context); // commerce_order--default
        const order_total = relationshipFieldOrder?.attributes.order_total;
        
        return order_total || null;
    },

    getOrderTotalNumber: (entity, context) => {
        const { mappedIncludes } = context;
        const order_total = EventEntity.getOrderTotalObject(entity, { mappedIncludes });
        let order_total_number = Number(order_total?.total?.number);

        return order_total_number || 0;
    },

    /**
     * This methiod has display purposes and can return data out of sync with model data!!!
     */
    getOrderTotalNumberDisplayPurposes: (entity, context) => {
        // const { intl, mappedIncludes } = context;
        let order_total_number = EventEntity.getOrderTotalNumber(entity, context);

        const eventTypeUuid = entity?.relationships?.field_event_type?.data.id || null;
        
        if (EventEntity.shouldDisplayZeroOrderTotalNumber(eventTypeUuid)) {
            order_total_number = 0;
        }

        if (EventEntity.isDepositReturn(eventTypeUuid)) {
            let field_payment_method = EventEntity.getPaymentGateway(entity);
            order_total_number = field_payment_method?.[`cash_on_delivery`];
        } else if (EventEntity.isDecreaseCashRegisterEventType(eventTypeUuid)) { // order cannot be of minus amount we must change value here!!!
            order_total_number = -order_total_number;
        }

        return order_total_number;
    },

    getOrderTotalFormatted: (
        // entity, context = null,
        number,
    ) => {

        return EventEntity.formatPrice(`PLN`, number);

        // const { mappedIncludes } = context;
        // const order_total = EventEntity.getOrderTotalObject(entity, { mappedIncludes });
        // return order_total?.total?.formatted || `-`;
    },

    getTaxRate: (entity, context) => {       
        const { intl, mappedIncludes } = context;
        const t = intl.formatMessage;

        const order_total = EventEntity.getOrderTotalObject(entity, { intl, mappedIncludes });
        const adjustments = order_total?.adjustments || [];

        const adjustmentTaxFiltered = adjustments.filter(adjustment => adjustment.type === `tax`);
        const tax = adjustmentTaxFiltered.length ? adjustmentTaxFiltered[0] : null;
/*                                                
        const adjustmentShippingFiltered = adjustments.filter(adjustment => adjustment.type === `shipping`);
        const shipping = adjustmentShippingFiltered.length ? adjustmentShippingFiltered[0] : null;
    
        const adjustmentPromotionFiltered = adjustments.filter(adjustment => adjustment.type === `promotion`);
        const promotion = adjustmentPromotionFiltered.length ? adjustmentPromotionFiltered[0] : null;
*/

        return tax?.percentage || null;
    },

    getUserName: (entity, context) => {
        const { intl, mappedIncludes, relationshipUser } = context;

        if (!relationshipUser) {
            relationshipUser = getRelationshipFromMappedIncludes(entity, `uid`, mappedIncludes); // user--user
        }

        return relationshipUser?.attributes?.display_name || null;
    },

    getCreatedAt: (entity) => {
        return entity?.attributes?.created || null;
    },

    getByUuid: async (eventUuid, authorization, intl) => {
        const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch_one', {
            parameters: {
                authorization, 
                eventUuid,
            },
            options: {
                langcode: intl.locale,
            },
        });

        return payload; // { data, included, jsonapi, links }
    },

    getRelatedEvents: (entity, context) => {
        const { mappedIncludes } = context;
        const field_event = getRelationshipFromMappedIncludes(entity, `field_event`, mappedIncludes); // "node--event"

        return field_event || null;
    },

    getRelatedEventsForSlaveEvent: async (entity, context) => {
        const { authorization, intl } = context;

        const { data: field_event } = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
            parameters: {
                authorization, 
                includes: [], // no includes attached to response
                filter: {
                    'field_event.id': entity.id, // search by uuid
                },
            },
            options: {
                langcode: intl.locale
            },
        });

        return field_event || null;
    },

    /**
     * Set in event entity what order it belongs to
     * 
     * @param {*} eventUuid 
     * @param {*} order_uuid 
     * @param {*} authorization 
     * @param {*} intl 
     * @returns 
     */
    setFieldOrder: async (eventUuid, order_uuid, context) => {
        const { authorization, intl } = context;

        const entityFields = {
            id: eventUuid,
            relationships: {
                field_order: {
                    data: {
                        type: `commerce_order--default`,
                        id: order_uuid, // order reference
                    },
                },
            }
        }

        const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_patch', {
            parameters: {
                authorization,
                entityFields,
            },
            options: {
                langcode: intl.locale
            },
        });

        return payload;
    },

    /**
     * Create two-way relation.
     * 
     * Relation for subject event was created in EventEntity::prepareData(values, context)
     * @todo And remove old relationship from previous master event to slave!!!
     * If you wish to add new/remove realtion to already existing multiple values field @see: https://www.drupal.org/project/jsonapi/issues/3017711
     */
     createSlaveToMasterEventRelationship: async (eventUuid, values, context) => {
        const { event_type: eventTypeUuid, field_event } = values;
        const { authorization, intl } = context;

        const isRelationshipSlaveEvent = EventEntity.isRelatedEventAllowed(eventTypeUuid);
// console.log(`isRelationshipSlaveEvent: `, isRelationshipSlaveEvent)
        /**
         * If isRelationshipSlaveEvent === true => newParentRelationUuid - slave uuid, eventRelationUuid - master uuid
         * If isRelationshipSlaveEvent === false, newParentRelationUuid - master uuid, eventRelationUuid - slave uuid
         */

        // @see: https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
        const differenceToRemove = values.field_event_init_abstract.filter(event => !values.field_event.includes(event));
// console.log(`differenceToRemove: `, differenceToRemove) 
// return;
        /**
         * 
         */
        if (isRelationshipSlaveEvent) { // We save from [Zakup vouchera, Zamowienie pizzy, Sprzedaż produktów, Wykorzystanie produktów, Zadatek] !!!
            const slaveUuid = eventUuid;

            // Remove old relationships
            differenceToRemove?.forEach(async masterUuid => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${masterUuid}/relationships/field_event`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                        'Accept': 'application/vnd.api+json',
                        'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                    },
                    body: JSON.stringify({
                        data: [ // in CMS this has multiple valuyes option enabled
                            {
                                type: `node--event`,
                                id: slaveUuid,
                            },
                        ],
                    }),
                })
                // const json = await response.json(); // !!! DELETE DOES NOT RETURN BODY
            })

            // Create new relationship STOP
            field_event?.forEach(async masterUuid => {
                await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${masterUuid}/relationships/field_event`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                        'Accept': 'application/vnd.api+json',
                        'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                    },
                    body: JSON.stringify({
                        data: [ // in CMS this has multiple valuyes option enabled
                            {
                                type: `node--event`,
                                id: slaveUuid,
                            },
                        ],
                    }),
                })
            })
        }

        // if (!isRelationshipSlaveEvent) { // We save from [Atrakcja, Pakiet], this condition is covered in EventEntity.prepareData
        // }
    },

    /**
     * Create two-way relation, relation for subject event was created in prepareData START
     * @todo And remove old relationship from previous master event to slave!!!
     * If you wish to add new/remove realtion to already existing multiple values field @see: https://www.drupal.org/project/jsonapi/issues/3017711
     * 
     * !!! This method now leaves orphant slave relation to master, because in this function we do not know what slave was removed because we do not pass here intesetion aray of what was initial values and what are now, so in DELETE reqwest there's no information about removed slave !!!
     */
    ___createSlaveToMasterEventRelationship: async (eventUuid, values, context) => {
        const { event_type: eventTypeUuid, field_event } = values;
        const { authorization, intl } = context;

        const isRelationshipSlaveEvent = EventEntity.isRelatedEventAllowed(eventTypeUuid);
// console.log(`isRelationshipSlaveEvent: `, isRelationshipSlaveEvent)
        /**
         * If isRelationshipSlaveEvent === true => newParentRelationUuid - slave uuid, eventRelationUuid - master uuid
         * If isRelationshipSlaveEvent === false, newParentRelationUuid - master uuid, eventRelationUuid - slave uuid
         */
        const newParentRelationUuid = eventUuid;

        const differenceToRemove = values.field_event_init_abstract.filter(event => !values.field_event.includes(event));
// console.log(`differenceToRemove: `, differenceToRemove) 
// return;
        differenceToRemove?.forEach(async eventRelationUuid => {

            await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${newParentRelationUuid}/relationships/field_event`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                },
                body: JSON.stringify({
                    data: [ // in CMS this has multiple valuyes option enabled
                        {
                            type: `node--event`,
                            id: eventRelationUuid,
                        },
                    ],
                }),
            })
            await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${eventRelationUuid}/relationships/field_event`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                },
                body: JSON.stringify({
                    data: [ // in CMS this has multiple valuyes option enabled
                        {
                            type: `node--event`,
                            id: newParentRelationUuid,
                        },
                    ],
                }),
            })
            return;

            // get old parent uuid and delete old parent relation to slave event, parent measns depending on isRelationshipSlaveEvent master/slave!!!
            const { data: oldParentRelationEventData } = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
                parameters: {
                    authorization, 
                    includes: [], // no includes attached to response
                    filter: {
                        'field_event.id': isRelationshipSlaveEvent ? newParentRelationUuid : eventRelationUuid, // search by uuid
                    },
                },
                options: {
                    langcode: intl.locale
                },
            });

            // Dla slave master nowo tworzonego to sie nie powinno wykonac, co innego jak modyfikacja slave mastera?
            if (oldParentRelationEventData?.length) {
// console.log(`oldParentRelationEventData: `, oldParentRelationEventData)
                const oldParentRelationUuid = oldParentRelationEventData?.[0]?.id;
                if (oldParentRelationUuid) {
// console.log(`oldParentRelationUuid: `, oldParentRelationUuid)
                    // const response = await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${oldParentRelationUuid}/relationships/field_event`, {
                    await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${oldParentRelationUuid}/relationships/field_event`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/vnd.api+json',
                            'Accept': 'application/vnd.api+json',
                            'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                        },
                        body: JSON.stringify({
                            data: [ // in CMS this has multiple valuyes option enabled
                                {
                                    type: `node--event`,
                                    id: isRelationshipSlaveEvent ? newParentRelationUuid : eventRelationUuid,
                                },
                            ],
                        }),
                    })
                    // const json = await response.json(); // !!! DELETE DOES NOT RETURN BODY
                }
            }

        })


        field_event?.forEach(async eventRelationUuid => {

            // get old parent uuid and delete old parent relation to slave event, parent measns depending on isRelationshipSlaveEvent master/slave!!!
            const { data: oldParentRelationEventData } = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
                parameters: {
                    authorization, 
                    includes: [], // no includes attached to response
                    filter: {
                        'field_event.id': isRelationshipSlaveEvent ? newParentRelationUuid : eventRelationUuid, // search by uuid
                    },
                },
                options: {
                    langcode: intl.locale
                },
            });

            // Dla slave master nowo tworzonego to sie nie powinno wykonac, co innego jak modyfikacja slave mastera?
            if (oldParentRelationEventData?.length) {
// console.log(`oldParentRelationEventData: `, oldParentRelationEventData)
                const oldParentRelationUuid = oldParentRelationEventData?.[0]?.id;
                if (oldParentRelationUuid) {
// console.log(`oldParentRelationUuid: `, oldParentRelationUuid)
                    // const response = await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${oldParentRelationUuid}/relationships/field_event`, {
                    await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${oldParentRelationUuid}/relationships/field_event`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/vnd.api+json',
                            'Accept': 'application/vnd.api+json',
                            'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                        },
                        body: JSON.stringify({
                            data: [ // in CMS this has multiple valuyes option enabled
                                {
                                    type: `node--event`,
                                    id: isRelationshipSlaveEvent ? newParentRelationUuid : eventRelationUuid,
                                },
                            ],
                        }),
                    })
                    // const json = await response.json(); // !!! DELETE DOES NOT RETURN BODY
                }
            }

            // if (!isRelationshipSlaveEvent) {
            //     return;
            // }

            // Create new relationship START
            // this removes old and just add new one as only
            // const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_patch', {
            //     parameters: {
            //         authorization,
            //         entityFields: {
            //             id: eventRelationUuid,
            //             relationships: {
            //                 field_event: {
            //                     data: [ // in CMS this has multiple valuyes option enabled
            //                         {
            //                             type: `node--event`,
            //                             id: newParentRelationUuid,
            //                         },
            //                     ],
            //                 },
            //             },
            //         },
            //     },
            //     options: {
            //         langcode: intl.locale
            //     },
            // });
            // this one appends to existing array
            await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/node/event/${eventRelationUuid}/relationships/field_event`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                },
                body: JSON.stringify({
                    data: [ // in CMS this has multiple valuyes option enabled
                        {
                            type: `node--event`,
                            id: newParentRelationUuid,
                        },
                    ],
                }),
            })
            // Create new relationship STOP

        })
    },
}
 
export default EventEntity;
