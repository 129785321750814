import React, { useState } from 'react'

// s4n
import { useIntl, Link, navigate } from "gatsby-plugin-intl"
import { connect } from 'react-redux'
import { checkoutChangeStep } from './../../ext/redux/actions/checkout';
import {
    CHECKOUT_STEP_CART,
    CHECKOUT_STEP_CUSTOMER_INFORMATION,
    CHECKOUT_STEP_SHIPPING_PAYMENT,
    CHECKOUT_STEP_SUMMARY,
} from './../utils/CheckoutSteps'
import SEO from './../../../../seo'

const checkoutSteps = [
    CHECKOUT_STEP_CART,
    CHECKOUT_STEP_CUSTOMER_INFORMATION,
    CHECKOUT_STEP_SHIPPING_PAYMENT,
    CHECKOUT_STEP_SUMMARY,
]

const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { activeStep, checkoutChangeStep } = props;

    return (
        <React.Fragment>
            <SEO 
                title={ t({ id: `soft4net_shop_commerce_${activeStep}` }) } 
                // keywords={`blog`} 
                // meta={meta} 
                // nodeId={data.node.drupal_internal__nid} 
            />

            <ol className="checkout-progress items row">
                {checkoutSteps.map((step, i) => {
                    const activeStepIndex = checkoutSteps.indexOf(activeStep);

                    // we cannot skip into future step without filling required data for current step and clicking next!!!
                    let onClick = null;
                    if (i < activeStepIndex) {
                        onClick = CHECKOUT_STEP_CART === step ? () => navigate(`/cart`) : () => checkoutChangeStep(step);
                    }
                    
                    return (
                        <li onClick={onClick} className={`checkout-progress--step ${step}${step === activeStep ? ` active` : ``}`}>
                            <div  className={`item cloud rounded bg-blue`}>
                                <div className={`title`}>
                                    <span className={`d-inline-block d-lg-none`}>
                                        {i + 1}
                                    </span>
                                    <span className={`d-none d-lg-inline-block`}>
                                        { t({ id: `soft4net_shop_commerce_${step}` }) }
                                    </span>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ol>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
  return {
    cart: state.shopReducers.cart,
  }
};
function mapDispatchToProps(dispatch) {
  return {
    checkoutChangeStep: (step) => dispatch(checkoutChangeStep(step)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Component);