import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { cartFetch, cartAddCoupon, cartRemoveCoupon } from '../../redux/actions/cart'

// s4n
import { injectIntl } from 'gatsby-plugin-intl'

class PromotionCode extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      message: null,
      couponCode: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.handleRemove = this.handleRemove.bind(this)
  }
/*
  async handleRemove() {
    const {cart: {cartToken, carts}, dispatch} = this.props
    const cart = carts[0]

    // await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/carts/${cart.id}/coupons`, {
    //   method: 'DELETE',
    //   headers: {
    //     'Commerce-Cart-Token': cartToken,
    //     'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
    //     'Content-Type': 'application/vnd.api+json',
    //     'Accept': 'application/vnd.api+json',
    //   },
    // })
    // dispatch(cartFetch())
    dispatch(cartRemoveCoupon(cart.id, this.state.couponCode))
  }
*/
  handleChange (event) {
    this.setState({
      couponCode: event.target.value
    })
  }

  async handleSubmit (event) {
    event.stopPropagation();
    event.preventDefault();

    const {cart: {carts}, dispatch} = this.props
    const cart = carts[0]
    // dispatch(cartAddCoupon(cart.id, this.state.couponCode))
    this.props.onCartAddCoupon(cart.id, this.state.couponCode)
    this.setState({
      couponCode: ``
    })
  }

  render () {
    const { intl } = this.props
    const t = intl.formatMessage

    const {cart: {carts}} = this.props
    const cart = carts[0]
/*
    if (cart.relationships.coupons.data && cart.relationships.coupons.data.length > 0) {
      return (
        <div className={`row`} key={`applied`}>
          <div className={`col-7`}>
            <div key={`message`} className={`alert alert-primary mb-0`}>
              {t({ id: `soft4net_shop_commerce_cart_page_promotion_code_applied` })}
            </div>
          </div>
          <div className={`col-5`}>
            <button type={`submit`} className={`btn btn-primary`} onClick={this.handleRemove}>
              {t({ id: `soft4net_shop_commerce_cart_page_promotion_code_remove` })}
            </button>
          </div>
        </div>
      )
    }
*/

    /**
     * If we have promotion set as:
     * Include the discount in the displayed unit price => adjustment of type promotion is not applied to cart object!!!
     * 
     * If we have promotion set as:
     * Only show the discount on the order total summary => adjustment of type promotion is applied to cart object!!! 
     * But in this case there';'s a problem with VAT calculation it does not discounted???
     */
    const isOff100PercentEachProductExceptCustomPrice = cart.relationships?.coupons?.data?.[0]?.id === process.env.SOFT4NET_DRUPAL_COMMERCE_PROMOTION_OFF_100_PERCENT_EACH_PRODUCT_EXCEPT_CUSTOM_PRICE_UUID;

    const isAnyCouponAppliedToOrderAlready = cart.attributes.order_total.adjustments.filter(adjustment => `promotion` === adjustment.type).length || isOff100PercentEachProductExceptCustomPrice || false;

    return (
      <form onSubmit={this.handleSubmit}>
        {this.props.cart.errorMessage !== null ? [
          <div key={`message`} className={`alert alert-warning`}>{this.props.cart.errorMessage}</div>
        ] : null}
        <div className={`form-row`}>
          <div className={`col`}>
            <input type={`text`} className={`form-control`} placeholder={t({ id: `soft4net_shop_commerce_cart_page_promotion_code_encouragement` })} value={this.state.couponCode} onChange={this.handleChange}/>
          </div>
          <button type={`submit`} className={`btn btn-primary`}>{t({ id: `soft4net_shop_commerce_cart_page_promotion_code_apply` })}</button>
          {isAnyCouponAppliedToOrderAlready &&
            <button onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              this.props.onCartRemoveCoupon(cart.id, null);
            }} type={`submit`} className={`btn btn-danger`}>{t({ id: `soft4net_shop_commerce_cart_page_promotion_codes_remove` })}</button>
          }
        </div>
        {/* <div>
          <small>Try using the <strong>FIFTEENPERCENT</strong> promotion code.</small>
        </div> */}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    cart: state.shopReducers.cart,
  }
};
const mapDispatchToProps = dispatch => ({
  onCartAddCoupon: (cartId, couponCode) => dispatch(cartAddCoupon(cartId, couponCode)),
  onCartRemoveCoupon: (cartId, couponCode) => dispatch(cartRemoveCoupon(cartId, couponCode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PromotionCode));