import { combineReducers } from 'redux';

import fontSize from './fontSize';
import highContrast from './highContrast';
import popup from './popup';
import intl from './intl';
import combineReducersShop from './../../../../src/components/s4n/Shop/ext/redux/reducers'
// import { connectRouter } from 'connected-react-router' // not for gatsby-ssr.js



export default history => combineReducers({
    fontSize,
    highContrast,
    popup,
    intl,
    shopReducers: combineReducersShop(history),
});
