import { handleActions } from 'redux-actions';
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import cart from './cart';
import cartFlyout from './cartFlyout'
import productDisplay from './productDisplay'
import wishlist from './wishlist'
import order from './order'
import user from './user'
import checkout from './checkout';
import event, * as fromEvent from './event'


// import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"
// import { createSelector } from 'reselect';



const isBrowser = typeof window !== 'undefined';

const currentStore = handleActions({
  'SELECT_STORE': (state, { payload }) => {
    isBrowser && localStorage.setItem('currentStoreId', payload)

    return {
      ...state,
      id: payload
    }
  }
}, {
  id: isBrowser && localStorage.getItem('currentStoreId') || process.env.REACT_APP_STORE_UUID,
})

const navigationReducer = handleActions({
  '@@router/LOCATION_CHANGE': (state) => {
    window.scrollTo(0, 0);
    return ({
      ...state,
    })
  },
}, {})



export default history => combineReducers({
  
  router: typeof history === 'undefined' ? null : connectRouter(history),
  // router: connectRouter(history),

  cart,
  cartFlyout,
  navigation: navigationReducer,
  checkout,
  productDisplay,
  currentStore,

  wishlist,
  order,
  user,

  event,
});
