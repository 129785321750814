/**
 * @todo: create Event entity model then it will be wrapped with repository for collection actions!!!
 */
import React from 'react'
import { jsonapiClient } from 's4n-jsonapi-client' // for events we do not want to user redux, we want to call an API instantly
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"
// import _ from 'lodash'
import { 
    kebabCase as _kebabCase
} from "lodash"
import GeneralUtils from './../../../../../utils/common'
import yaml from 'js-yaml';
import moment from 'moment'

import classNames from 'classnames'

import EventEntity from './../Entity'

export const PAYMENT_METHODS = [`cash_on_delivery`, `credit_card`, `bank_transfer`]; // @todo: implement payment method picker

export const SALES_DOCUMENTS = [`receipt`, `invoice`, `invoice_person`]; // @todo: implement payment method picker

export const PAYMENT_METHOD_2_ICON = {
    cash_on_delivery: <i class="ti-money text-success border-success"></i>,
    credit_card: <i class="ti-credit-card text-primary border-primary"></i>,
    bank_transfer: <i class="ti-mouse-alt text-pink border-pink"></i>,
    pizza_tip: <i class="ti-pie-chart text-pink border-pink"></i>,
};

const EventRepository = {
    getTodayEvents: () => {

    },

    hasOpenEventTypeForToday: () => {

    },

    hasCloseEventTypeForYesterday: () => {

    },

    /**
     * 
     * @param {*} pageSize 
     * @param {*} startRow 
     * @param {*} endRow 
     * @returns 
     * 
     * @todo: implement pagination
     * @see: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/pagination
     * @see: https://www.drupal.org/project/jsonapi_page_limit
     */
     getServerData: async (pageSize, pageIndex, filters, context) => { // implement show how many rows per page

        const {  
            intl,
            authorization,
            EventActions,
        } = context;

        const t = intl.formatMessage;

// console.log(`filters unprocessed: `, filters)
        let filter = {}
        filters.forEach(oFilter => {
            // filter[oFilter.id] = oFilter.value;

            if (Array.isArray(oFilter.value)) {
/*
                subValue is:
                {
                    path: `created`,
                    operator: '>=',
                    value: moment(newValue?.[0]).startOf('day').unix(),
                },
*/
                oFilter.value.forEach((subValue, index) => {
                    if (subValue) {
                        filter[`${oFilter.id}_${index}`] = subValue;
                    } else {
                        delete filter[`${oFilter.id}_${index}`];
                    }
                })
                delete filter[oFilter.id];
            } else {
                filter[oFilter.id] = oFilter.value;
            }
        });
// console.log(`filter processed: `, filter)

        // @todo: refactor to be part of an action!!!
        const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {

            // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
            parameters: {
                authorization, 
                filter,
                page: {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                }
            },
            options: {
                langcode: intl.locale,
            },
        });

        const { data: items, included, jsonapi, links } = payload;
        const mappedIncludes = getMappedIncludes(included);

        let tableDataEventSubRows = {}; // contains all subrows for event uuid in an object, all subrows are array items
        let tableDataEventIsSubrow = {}; // this tells us to not display event on root of a table, mark this subrow as attached to row so we later know that we do not display this subRow on root of table, but display only as attached to row!
        /**
         * Fills all relation data and sets "isSubRow" flags
         */
        items.forEach((item, index) => {
            const eventTypeUuid = item?.relationships?.field_event_type?.data.id || null;
            const isRelationshipSlaveEvent = EventEntity.isRelatedEventAllowed(eventTypeUuid);

//             if (isRelationshipSlaveEvent) {
//                 const masterRelationshipFieldEvents = getRelationshipFromMappedIncludes(item, `field_event`, mappedIncludes); // node--event
//                 if (masterRelationshipFieldEvents) {
//                     /**
//                      * An item is a "slave" event [Zakup vouchera, Zamowienie pizzy, Sprzedaż produktów, Wykorzystanie produktów, Zadatek] !!!
//                      * An event is a "master" event [Atrakcja, Pakiet] !!!
//                      */
//                     masterRelationshipFieldEvents?.forEach((event, eventIndex) => {
//                         const subRow = EventEntity.getTableRow(item, eventIndex, { intl, mappedIncludes, EventActions }) // subRow is always a "slave" event
// // console.log(subRow)
//                         if (subRow) {
//                             tableDataEventIsSubrow[item.id] = true;

//                             if (!tableDataEventSubRows?.[event.id]) {
//                                 tableDataEventSubRows[event.id] = [];
//                             }

//                             if (!tableDataEventSubRows[event.id].find(row => row.uuid === item.id)) {
//                                 tableDataEventSubRows[event.id].push(subRow);
//                             }
//                         }
//                     });
//                 }
//             } 
            if (!isRelationshipSlaveEvent) {
                const slaveRelationshipFieldEvents = getRelationshipFromMappedIncludes(item, `field_event`, mappedIncludes); // node--event
                if (slaveRelationshipFieldEvents) {
                    /**
                     * An item is a "master" event [Atrakcja, Pakiet] !!!
                     * An event is a "slave" event [Zakup vouchera, Zamowienie pizzy, Sprzedaż produktów, Wykorzystanie produktów, Zadatek] !!!
                     */
                    slaveRelationshipFieldEvents?.forEach((event, eventIndex) => {
                        const subRow = EventEntity.getTableRow(event, eventIndex, { intl, mappedIncludes, EventActions }) // subRow is always a "slave" event
// console.log(subRow)
                        if (subRow) {
                            tableDataEventIsSubrow[event.id] = true;

                            if (!tableDataEventSubRows?.[item.id]) {
                                tableDataEventSubRows[item.id] = [];
                            }

                            if (!tableDataEventSubRows[item.id].find(row => row.uuid === event.id)) {
                                tableDataEventSubRows[item.id].push(subRow);
                            }
                        }
                    });
                }
            }
        })

        let tableData = [];
        /**
         * @see: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/includes#s-fetch-all-at-once-using-include
         * Also, notice how there is now a data key in each relationship object. 
         * That let's you correlate the included resource objects with the resource objects that referenced them.
         * 
         * If we display this data in table cell it can only be displaybale data not objects. objects we can put inside s4n custom raw key, this key contents won't be displayed in table cells!!!
         * We can use displayable primitive data such as Numbers, String but no Object that cannot be cast to String
         * 
         * data field in JSON:API gets populated when we use incude in request
         */
        items.map((item, index) => {
            const row = EventEntity.getTableRow(item, index, { intl, mappedIncludes, EventActions });

            // Put on root of a table only if there is not a subrow, if it's contained in tableDataEventIsSubrow it is a subrow and we do not want to display it on root of a table
            if (!tableDataEventIsSubrow?.[item.id]) {
                if (tableDataEventSubRows?.[item.id]?.length) {
                    row.subRows = tableDataEventSubRows?.[item.id];
                }

                tableData.push(row);
            }
        })

        return {
            serverData: tableData,
            hasNextPage: links?.hasOwnProperty(`next`),
            hasPrevPage: links?.hasOwnProperty(`prev`),
        };
    },

    /**
     * 
     * 
     * 
     * @returns order UUID
     */
     makeOrder: async (context) => {

        const { props, intl, eventData, eventUuid } = context;

        // const { cart: cartReducer } = props;
        // if (CommerceHelper.isCartEmpty(props)) {
        //     return false;
        // }

        // await props.getCheckout(cartReducer.carts[0]);

// console.log(cartReducer.carts[0]); return;
// console.log(props?.checkout?.orderData); return; // "commerce_order--default"

        if (!props?.checkout?.orderData) {
            return false;
        }

        const taxNumberInitial = {
            type: 'other',
            value: null,
            // verification_result: [],
            // verification_state: null,
            // verification_timestamp: null,
        };
        let shippingTaxNumber = {}, billingTaxNumber = {};
        shippingTaxNumber = billingTaxNumber = {
            ...taxNumberInitial,
            value: `1234567890`,
        }
        
        const emailAddress = `test@test.pl`, 
            emailConfirmAddress = `test@test.pl`, 
            billingEmailAddress = `test@test.pl`;

        const addressInitial = {
            email: emailAddress,
            // email_confirm: emailConfirmAddress,

            // Part of shipping_information or billing_information payload START
            country_code: `PL`,
            given_name: `given_name`, 
            family_name: `family_name`, 
            // organization: ``, // Firma
            address_line1: `address_line1`, // Ulica
            address_line2: `address_line2`, // Ulica (dodatkowe informacje)
            locality: `locality`,
            administrative_area: `administrative_area`, // State
            postal_code: `12-345`, // Zip code FOR ALABAMA CHANGLE THIS IF OTHER ADDRESS !!!
            // Part of shipping_information or billing_information payload STOP
        
            // tax_number: shippingTaxNumber.value, // NIP
        };
        const billingAddress = addressInitial;

        const billing_information = {
            // tax_number: billingTaxNumber,
            address: {       
                ...billingAddress,
            },
        };

// console.log(`eventData.attributes.field_payment_method: `, eventData.attributes.field_payment_method); return;
        let payment_gateway_id = PAYMENT_METHODS[0];
/*
        let payment_gateway_id = null;
        for (let oPaymentMethodKey in eventData.attributes.field_payment_method) { // this can contain more than one payment method with it's values!
            if (null === payment_gateway_id) {
                payment_gateway_id = oPaymentMethodKey;
                break;
            }
        }
*/
/*
        Object.keys(eventData.attributes.field_payment_method).forEach(oPaymentMethodKey => {
            if (null === payment_gateway_id) {
                payment_gateway_id = oPaymentMethodKey;
            }
        });
*/

        let payment_instrument = {  
            payment_details: [],
            
            // payment_gateway_id: eventData.payment_method,
            payment_gateway_id: payment_gateway_id, // main payment method

            payment_method_id: null,
            payment_method_type: null,
        }

        let patchCheckoutAttributes = {
            email: emailAddress, // s4n
            // email_confirm: emailConfirmAddress, // s4n
            // contact_information[email] ?? on przelewy24 form
            // shipping_information, // we disable it because we do not installed commerce_shipping module!!!
            billing_information,
            payment_instrument,         
            
// field_payment_method: yaml.dump(eventData.attributes.field_payment_method, {
//     // quotingType: `"`,
// }), // składowa zamówienia customowa

        }

        let response = null;
        let hasError = false;
        let orderUuid = null;

/*
        const billing = await props.patchCheckout(
            props.checkout.orderData, {
                email: emailAddress, // s4n
                billing_information,
                // field_payment_method: yaml.dump(eventData.attributes.field_payment_method, {
                //     quotingType: `"`,
                // }), // składowa zamówienia customowa
                field_payment_method: `credit_card:12|cash_on_delivery:15`, // składowa zamówienia customowa
            }
        )
        const payment = await props.patchCheckout(
            props.checkout.orderData, {
                payment_instrument: {
                    ...payment_instrument,
                    payment_method_type_id: null,
                }
            }
        )
*/

        const shipping_payment_method_response = await props.patchCheckout(
          props.checkout.orderData, {
            ...patchCheckoutAttributes,

            // Event was created now create an order with context of what event it belongs to
            // Set in order field_event_uuid that contains just created event uuid
            // we need this field also for commerce_stock in case when isShopping event occured so we increase stock not decrease as normally
            ...(eventUuid ? {field_event_uuid: eventUuid} : {})

          }
        )

// console.log(shipping_payment_method_response)
        // if (shipping_payment_method_response... ... ...) {
        //     return orderUuid;
        // }

        try {
            response = await props.postCheckout(
                props.checkout.orderData, {
                    // payment_instrument,
                }
            )

// console.log(response); // "commerce_payment--payment_default"

            await props.cartFetch(); // we want to clear cart contents, after order temporary cart is converted into order

            orderUuid = props.checkout.orderData.id;

        } catch (error) {
            console.log(error)
            hasError = true;
        }

        return orderUuid;
    },

    deleteOrder: async (order_uuid, authorization) => {
        await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/commerce_order/default/${order_uuid}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                'Authorization': `${authorization.token_type} ${authorization.access_token}`,
            },
        })
    },
}

export default EventRepository;
