import React, { useState, useEffect } from 'react'
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import cn from 'classnames'
import { exchangeQuantityToPriceForCustomPriceProduct } from './../../../../../Shop/ext/pages/Cart'


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { orderItems, mappedIncludes, tableCellDisplay } = props;

/*
    const [wishlist, setWishlist] = useState([]);

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {       
        const wishlist = await props.onWishlistFetch(authorization);
        // console.log(wishlist)
        if (wishlist.included) {
            const wishlist_commerce_product_variation = wishlist.included.filter(item => item.attributes);
            setWishlist(wishlist_commerce_product_variation);
        }
    }
*/

    const oClassNames = cn({
        'table table-hover': true,
        'table-striped': !tableCellDisplay,
        'table-not-striped table-not-bordered table-sm': tableCellDisplay,
    });

    return (
        <React.Fragment>
            <table role="table" className={oClassNames} border={0}>
                {!tableCellDisplay &&
                    <tr key={`order-items-header`}>
                        <th>Lp.</th>
                        <th>Nazwa</th>
                        <th>Ilość</th>
                        <th>Cena</th>
                    </tr>
                }
                {orderItems.map((orderItem, index) => {
// console.log(`orderItem: `, orderItem)
// console.log(orderItem.relationships.purchased_entity) 
// return null;

                    const purchaseEntity = getRelationshipFromMappedIncludes(orderItem, 'purchased_entity', mappedIncludes);
// console.log(`purchaseEntity: `, purchaseEntity)
                    // const product = getRelationshipFromMappedIncludes(purchaseEntity, 'product_id', mappedIncludes);
                    // if (null === product) {
                    //     return null;
                    // }

                    return (
                        <tr key={orderItem.id}>
                            {!tableCellDisplay &&                             
                                <td className="cart-block--offcanvas-cart-table__title ___w-50">
                                    {index + 1}. 
                                </td>
                            }
                            <td className="cart-block--offcanvas-cart-table__title ___w-50">
                                {/* <Link to={product.attributes.path.alias}> */}
                                    {orderItem.attributes.title} {purchaseEntity.id === `8774110d-1357-41af-87fe-c2ba6d86cd2e` ? `` : purchaseEntity.attributes.sku}
                                {/* </Link> */}
                                {orderItem.attributes.field_place &&
                                    <React.Fragment>
                                        <br />(Sala: {orderItem.attributes.field_place})
                                    </React.Fragment>
                                }
                            </td>
                            <td className="cart-block--offcanvas-cart-table__quantity">
                                {purchaseEntity.id === `8774110d-1357-41af-87fe-c2ba6d86cd2e` ? 
                                    `${exchangeQuantityToPriceForCustomPriceProduct(orderItem.attributes.quantity)} zł`
                                    : 
                                    parseInt(orderItem.attributes.quantity)
                                }
                                {/* <input className="form-control" type={`number`} size={5} min={0} defaultValue={parseInt(orderItem.attributes.quantity)} onChange={e => {
                                    dispatch(cartUpdateItem(orderItem, {quantity: e.target.value}))
                                }}/> */}
                            </td>
                            {!tableCellDisplay &&                             
                                <td className="cart-block--offcanvas-cart-table__price w-15">
                                    {orderItem.attributes.total_price.formatted}
                                </td>
                            }
                            {/* <td className="cart-block--offcanvas-cart-table__remove text-right">
                                <button className="btn btn-primary" onClick={() => { dispatch(cartRemove(orderItem)) }}>Remove</button>
                            </td> */}
                        </tr>
                    )
                })}
            </table>
        </React.Fragment>
    )
};

export default Component;