import { handleActions } from 'redux-actions';


const INITIAL_STATE = {
  // JSON:API response structure
  data: [], // all events
  included: [],

  // custom structure
  event_types: {
    data: [],
    included: [],
  },
  product_fetch: {
    data: [],
    included: [],
  },
  notification: {
    data: null,
    included: [],
  },
  today: {
    data: null,
    included: [],
  },
  lastOpenDayEvent: null,
  yesterdayHasCloseEvent: null,
  cashRegisterAmount: null,
  applicationVersionNumberBackend: null,
  last_added_event_uuid: null,

  loading: false,
  itemCount: 0,
  errorMessage: null,
};

export default handleActions({

  EVENT_ADD_SUCCEEDED: (state, { payload }) => {
    const { data, included } = payload;

    return {
      ...state,
      last_added_event_uuid: data?.id,
    }
  },

  RESET_LAST_ADDED_EVENT_UUID: (state) => ({
    ...state,
    last_added_event_uuid: null,
  }),

  EVENT_FETCH_SUCCEEDED: (state, { payload }) => {
    return state;

    const { data, included } = payload;

    return {
      ...state,
      data: data,
      included: included,
    }
  },

  EVENT_PATCH_SUCCEEDED: (state, { payload }) => {
    const { data } = payload;

    return {
      ...state,
      // profile_customer: data,
      // included,
    }
  },

  EVENT_PATCH_FAILED: (state, { payload }) => {
// console.log('PROFILE_PATCH_FAILED: ', payload);
    return state;
  },

  EVENT_REMOVE_SUCCEEDED: (state, { payload }) => {
    // const { data } = payload;
    return {
      ...state,
      // profile_customer: data,
      // included,
    }
  },

  EVENT_TYPES_SUCCEEDED: (state, { payload }) => {
    const { data, included } = payload;

    return {
      ...state,
      event_types: {
        // data, 
        // included,
        ...payload,
      }
    }
  },

  // @todo: refactor this to proper reducer
  PRODUCT_FETCH_SUCCEEDED: (state, { payload }) => {
    const { data, included } = payload;

    return {
      ...state,
      product_fetch: {
        // data, 
        // included,
        ...payload,
      },
    }
  },

  // @todo: refactor this to proper reducer
  NOTIFICATION_FETCH_SUCCEEDED: (state, { payload }) => {
    const { data, included } = payload;

    return {
      ...state,
      notification: {
        // data, 
        // included,
        ...payload,
      },
    }
  },

  // @todo: refactor this to proper reducer
  TODAY_FETCH_SUCCEEDED: (state, { payload }) => {
    const { data, included } = payload;

    return {
      ...state,
      today: {
        // data, 
        // included,
        ...payload,
      },
    }
  },

  // @todo: refactor this to proper reducer
  YESTERDAY_HAS_CLOSE_EVENT_FETCH_SUCCEEDED: (state, { payload }) => {
    const { 
      data, included, 
      lastOpenDayEvent,
    } = payload;

    return {
      ...state,
      lastOpenDayEvent,
      yesterdayHasCloseEvent: !!data.length,
    }
  },

  GET_CASH_REGISTER_AMOUNT_SUCCEEDED: (state, { payload }) => {
    const { data, included } = payload;

    return {
      ...state,
      cashRegisterAmount: data?.[0]?.attributes?.field_cash_register || 0,



      applicationVersionNumberBackend: data?.[0]?.attributes?.field_application_version_number || null,



    }
  },

}, INITIAL_STATE);
