/**
 * Just view/template component, no model/data logic!
 * @todo: get from drupal commerce option for Tax settings => "Prices are entered with taxes included." => /en/store/1/edit
 */
import React
        // , { useState, useEffect } 
from 'react';
// import SEO from "./../../seo"

// Shop
// import { formatCurrency } from './../ext/utils/currency'

// s4n
import { useIntl } from "gatsby-plugin-intl"
// import HttpClient from './../../../../utils/HttpClient'
// import $ from "jquery"



/**
 * Displays selected variation price
 * 
 * @param { intl, selectedVariation } props 
 */
const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { selectedVariation } = props;
    const printLabel = props.printLabel || false;

    // const [exchangeRate, setExchangeRate] = useState(null);

    // /**
    //  * text === String
    //  */
    // const extractDomFromRenderedEntity = (text = null, className = null) => {
    //     if (typeof text === `undefined` || typeof className === `undefined`) {
    //         return null;
    //     }
    //     const $entityRenderedDom = $(text);
    //     const $node = $entityRenderedDom.find(className);
    //     return $node;
    // }

    // const fetchData = async () => {
    //     const response = await HttpClient.get(`/soft4net/exchange_rate/ecb/USD_PLN`, {});
    //     // console.log(response)
    //     if (200 === response.status) {
    //         setExchangeRate(Number(response.data));
    //     }
    // }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    // useEffect(() => {
    //     if (`PLN` === selectedVariation.price.currencyCode) {
    //         return;
    //     }

    //     fetchData();
    // }, []);

    

// @see: https://www.drupal.org/project/commerce_currency_resolver/issues/3120422 START
    // if (`PLN` !== selectedVariation.price.currencyCode && null !== exchangeRate) {
    //     selectedVariation.price.currencyCode = `PLN`;
    //     selectedVariation.price.number = selectedVariation.price.number * exchangeRate;
    // }
    // const priceResolvedAndFormatted = formatCurrency(selectedVariation.price.currencyCode, selectedVariation.price.number);

    // const response = await HttpClient.jsonapi(intl.locale, `commerce_product_variation/default`, `filter[entity_type][value]=commerce_product&filter[field_storage_config_type][value]=list_string`);
    // console.log(response)

// @see: https://www.drupal.org/project/commerce_currency_resolver/issues/3120422 STOP


    // console.log(selectedVariation)

    // const priceResolvedAndFormatted = extractDomFromRenderedEntity(selectedVariation.entityRendered, `.field--type-commerce-price .field__item`).text();
    if (!(selectedVariation && selectedVariation.resolved_price && selectedVariation.calculated_price)) {
        return null;
    }

    let hasDiscount = false;
    let percentageDiscountDecrease = 0;
    if (Number(selectedVariation.calculated_price.number) < Number(selectedVariation.resolved_price.number)) {
        hasDiscount = true;
        percentageDiscountDecrease = Math.round(100 - Number(selectedVariation.calculated_price.number) / Number(selectedVariation.resolved_price.number) * 100); // % Decrease = Decrease ÷ Original Number × 100
    }

    return (
        <React.Fragment>
            <p className={`variation-price${hasDiscount ? ` has-discount` : ``}`}>
                {printLabel && 
                    <React.Fragment>
                        {t({ id: "soft4net_shop_commerce_product_page_price" })}
                        {` `}
                    </React.Fragment>
                }
                {hasDiscount && 
                    <React.Fragment>
                        <span className="percentage-discount-label">Promocja: -{percentageDiscountDecrease}%</span>
                        <s className="px-1"><small>{selectedVariation.resolved_price.formatted}</small></s>
                    </React.Fragment>
                }
                <strong>{selectedVariation.calculated_price.formatted}</strong>
                {` `}
                {/* <span className="plus-vat">+ VAT</span> */}
            </p>
        </React.Fragment>
    )
}

export default ComponentStateless