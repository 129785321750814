import React, { useState, useEffect } from 'react';

// s4n
import { useIntl } from "gatsby-plugin-intl"
import classNames from 'classnames';



/**
 * Modifies quantity added to cart
 * This componenet is just an interface for changing parent state.addToCartQuantity
 * 
 * @param { quantity, onQuantityModify } props 
 */
const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { printLabel, quantity, onQuantityModify: setQuantity } = props;
    // const [quantity, setQuantity] = useState(props.quantity);

    const decrementQuantity = (event) => {
        if (parseInt(quantity) === 1) {
            return false;
        }

        setQuantity(quantity - 1);
    }

    const incrementQuantity = (event) => {
        setQuantity(quantity + 1);
    }

    const oClassNames = classNames({
        'product-quantity-add-to-cart': true,
        'small': props.displayAsSmall,
    });

    return (
        <React.Fragment>
            <div className={oClassNames}>
                {printLabel &&
                    <div className="title">{t({ id: `soft4net_shop_commerce_product_page_quantity_add_to_cart_title` })}</div>
                }
                <div className="content">
                    <button type="button" onClick={decrementQuantity}>
                        <i className="fas fa-minus"></i>
                    </button>
                    <span className="quantity">{quantity}</span>
                    <button type="button" onClick={incrementQuantity}>
                        <i className="fas fa-plus"></i>
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Component;
