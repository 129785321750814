// import _ from "lodash"
import moment from 'moment'
import 'moment/locale/pl'  // without this line it didn't work for PL!!!
// import { injectIntl } from "gatsby-plugin-intl"
import { globalHistory } from "@reach/router"


class Utils {
    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * @see: https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error
     * 
     * btoa() is like base64_encode()
     */
    static btoa = (str) => {
        const Buffer = Buffer || require(`buffer`).Buffer;

        if (typeof btoa === `undefined`) {
            return new Buffer(str, `binary`).toString(`base64`);
        }

        return btoa(str);
    }

    /**
     * @see: https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error
     * 
     * atob() is like base64_decode()
     */
    static atob = (base64Encoded) => {
        const Buffer = Buffer || require(`buffer`).Buffer;

        if (typeof atob === `undefined`) {
            return new Buffer(base64Encoded, `base64`).toString(`binary`);
        }

        return atob(base64Encoded);
    }

    /**
     * You can use btoa() and atob(). btoa() is like base64_encode() and atob() like base64_decode().
     */
    static getVersion = (version = undefined, time = undefined) => {
        return typeof version === `undefined` ? `?v=${new Date(time).valueOf()}` : version;
        // return typeof version === `undefined` ? `?v=${this.btoa(time)}` : version;
        //return Date.now() + Math.random();
    }

    // @see: https://momentjs.com/
    // @see: https://momentjs.com/docs/#/i18n/
    static dateFormat = (date, format = null, locale = null) => {
        !format && (format = "L")
        locale && moment.locale(locale)

        return moment(new Date(date)).format(format)
    }

    /**
     * Formats duration taken from drupal as minutes
     * 
     * @param {*} duration in minutes
     * @param {*} format 
     * @param {*} locale 
     * @returns 
     */
    static duration = (duration, format = null, locale = null) => {
        // !format && (format = "L")
        locale && moment.locale(locale)

        const seconds = moment.duration(duration, 'minutes').asSeconds();
        return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format("HH:mm");
        // return moment.duration(duration, 'minutes').asHours().toFixed(2);
        // return moment.duration(duration, 'minutes').locale(locale).humanize();
    }

    /**
     * @see: https://stackoverflow.com/questions/35352638/how-to-get-parameter-value-from-query-string
     * @see: https://stackoverflow.com/questions/747641/what-is-the-difference-between-decodeuricomponent-and-decodeuri
     */
    static getSearchParam = (param) => {
        let query = globalHistory.location.search.replace('?', '')
        let vars = query.split('&');
        // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (let i=0; i<vars.length; i++) {
            let pair = vars[i].split('=');
            // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] === param) {
                return decodeURIComponent(pair[1]);
            }
        }

        return decodeURIComponent('');
    }

    // @see: https://stackoverflow.com/questions/5448545/how-to-retrieve-get-parameters-from-javascript
    static findGetParameter = (parameterName) => {
        var result = null,
            tmp = [];

        globalHistory.location.search
        // location.search
            .substr(1)
            .split("&")
            .forEach((item) => {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    /**
     * https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
     */
    // static hexToRgbA = (hex) => {
    static hexToRgb = (hex) => {
        let c;

        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');

            // return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',') + ',1)';
            return [(c>>16)&255, (c>>8)&255, c&255];
        }

        return [];
        // throw new Error('Bad Hex');
    }

    // @see: https://stackoverflow.com/questions/11233498/json-stringify-without-quotes-on-properties/11233515
    static stringify = (obj_from_json) => {
        if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)) {
            // not an object, stringify using native function
            return JSON.stringify(obj_from_json);
        }
        // Implements recursive object serialization according to JSON spec
        // but without quotes around the keys.
        let props = Object
            .keys(obj_from_json)
                .map(key => `${key}:${this.stringify(obj_from_json[key])}`)
                .join(",");

        return `{${props}}`;
    }

    static rot13 = (str) => {
        var input     = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var output    = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
        var index     = x => input.indexOf(x);
        var translate = x => index(x) > -1 ? output[index(x)] : x;

        return str.split('').map(translate).join('');
    }

    static getScreenSize = (windowInnerWidth = 0) => {
        // Bootsrap 4 default
        const bootstrapConfig = {
            breakpoint: {
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
            }
        }

        let screenSize = ``;

        if (windowInnerWidth < bootstrapConfig.breakpoint.sm) {
            screenSize = `xs`;
        } else if (bootstrapConfig.breakpoint.sm <= windowInnerWidth && windowInnerWidth < bootstrapConfig.breakpoint.md) {
            screenSize = `sm`;
        } else if (bootstrapConfig.breakpoint.md <= windowInnerWidth && windowInnerWidth < bootstrapConfig.breakpoint.lg) {
            screenSize = `md`;
        } else if (bootstrapConfig.breakpoint.lg <= windowInnerWidth && windowInnerWidth < bootstrapConfig.breakpoint.xl) {
            screenSize = `lg`;
        } else if (bootstrapConfig.breakpoint.xl <= windowInnerWidth) {
            screenSize = `xl`;
        }

        return `screen-` + screenSize;
    }

    static validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static shadeColor = (color, percent) => {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);
    
        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);
    
        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  
    
        var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB;
    }

    static isBrowser = () => typeof window !== 'undefined'


    
    static isLoginAllowed = async (authorization) => {
        let isLoginAllowed = false;
      
        try {
          // Here we check IP Address and if user is Aministrator by passing authorization token
          const response = await fetch(`${process.env.SOFT4NET_SOURCE_BASE_URL}/pl/soft4net/authorization/isallowed`, {
              method: 'GET',
              headers: {
                  'Accept': 'application/vnd.api+json',
                  'Content-Type': 'application/vnd.api+json',
                  'Authorization': `${authorization.token_type} ${authorization.access_token}`,
              },
          })
          isLoginAllowed = await response.json();
    // console.log(`isLoginAllowed: `, isLoginAllowed)
        } catch (e) {
          console.log(e);
          isLoginAllowed = false;
        }
    
        return isLoginAllowed;
    }

    static login = async (username, password, scope, context) => {
        const { drupalOauthClient, updateAuthenticatedUserState, onUserLogin } = context;

        try {
            await drupalOauthClient.handleLogin(username, password, scope);
            updateAuthenticatedUserState(true);

            // Call after login actions!!!
            const authorization = drupalOauthClient.isLoggedIn();

            const isLoginAllowed = Utils.isLoginAllowed(authorization);
            if (!isLoginAllowed) {
                throw new Error('Login is not allowed');
            }

            await onUserLogin({authorization});
            // await this.props.onUserLogin({
            //     auth: {
            //         drupalOauthClient: this.props.drupalOauthClient, 
            //         userAuthenticated: this.props.userAuthenticated, 
            //         updateAuthenticatedUserState: this.props.updateAuthenticatedUserState,
            //     }
            // });

            // Ewidencja czasu pracy 
            await fetch(`${process.env.SOFT4NET_SOURCE_BASE_URL}/soft4net/authorization/start`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                },
            })
        } catch (error) {
            console.log(error);
            return false;    
        }

        // we were authenticated because no exception were thrown
        return true;
    }

    static logout = async (context) => {
        const { drupalOauthClient, updateAuthenticatedUserState, onUserLogout } = context;

        if (!drupalOauthClient) {
            return false;
        }

        const authorization = drupalOauthClient.isLoggedIn();
    
        await drupalOauthClient.handleLogout();
        updateAuthenticatedUserState(false);
        await onUserLogout();

        // Ewidencja czasu pracy 
        await fetch(`${process.env.SOFT4NET_SOURCE_BASE_URL}/soft4net/authorization/stop`, {
            method: 'POST',
            headers: {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                'Authorization': `${authorization.token_type} ${authorization.access_token}`,
            },
        })
    }
}

export default Utils