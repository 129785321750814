import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'
import { jsonapiClient } from 's4n-jsonapi-client'
import moment from 'moment';
import {
    TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID,
    TAXONOMY_TERM_EVENT_TYPES_DAY_OPEN_UUID,
    TAXONOMY_TERM_EVENT_TYPES_DAY_CLOSE_UUID,
} from './../../../../AccountManagement/Events/Entity'
// import { cartFetch } from './cart'


const suppressException = process.env.SOFT4NET_CREATE_ACTION_THUNK_SUPPRESS_EXCEPTION === `true` ? true : false;


export const eventAdd = createActionThunk('EVENT_ADD', async (entityFields, authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_add', {
        parameters: {
            authorization,
            entityFields,
        },
        options: {
            langcode: intl.locale
        },
    });

    // store.dispatch(eventFetch(authorization)); // we do not use this action, we use direct server get method in react table is not refreshed
    store.dispatch(todayFetch({authorization}));
    store.dispatch(yesterdayHasCloseEventFetch({authorization}));
    store.dispatch(notificationFetch({authorization}));
    store.dispatch(getCashRegisterAmount({authorization}));
    store.dispatch(productFetch({authorization}));

    // we must return payload to reducer!!!
    return payload; 
}, suppressException)

// export const eventFetch = createActionThunk('EVENT_FETCH', async (authorization, store) => {
//     const state = store.getState();
//     // const { event: { items, included } } = state.shopReducers;
//     const { intl } = state.intl;

//     // this Promise returns { wishlist: data, included: included }
//     return await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {

//         // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
//         parameters: {
//             authorization, 
//         },
//         options: {
//             langcode: intl.locale
//         },
//     });

// }, suppressException)

export const eventPatch = createActionThunk('EVENT_PATCH', async (entityFields, authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_patch', {
        parameters: {
            authorization,
            entityFields,
        },
        options: {
            langcode: intl.locale
        },
    });

    // do we need to do that because response contains updated events already!!!
    // store.dispatch(eventFetch(authorization)); // we do not use this action, we use direct server get method in react table is not refreshed
    store.dispatch(todayFetch({authorization}));
    store.dispatch(yesterdayHasCloseEventFetch({authorization}));
    store.dispatch(notificationFetch({authorization}));
    store.dispatch(getCashRegisterAmount({authorization}));
    store.dispatch(productFetch({authorization}));

    // we must return payload to reducer!!!
    return payload; 
}, suppressException)

// THIS ACTION HAS NO REDUCER BECAUSE IT DOES NOT NEED IT
export const eventRemove = createActionThunk('EVENT_REMOVE', async (payload, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    const { event, order, authorization } = payload;

    await jsonapiClient(process.env.REACT_APP_API_URL, 'event_remove', {
        parameters: {
            event,
            order,
            authorization,
        },
        options: {
            langcode: intl.locale
        },
    });

    // store.dispatch(eventFetch(authorization)); // we do not use this action, we use direct server get method in react table is not refreshed
    store.dispatch(todayFetch({authorization}));
    store.dispatch(yesterdayHasCloseEventFetch({authorization}));
    store.dispatch(notificationFetch({authorization}));
    store.dispatch(getCashRegisterAmount({authorization}));
    store.dispatch(productFetch({authorization}));

    // we must return payload to reducer!!!
    // return { data: [], included: [] };
}, suppressException)

// THIS ACTION HAS NO REDUCER BECAUSE IT DOES NOT NEED IT
export const eventTypes = createActionThunk('EVENT_TYPES', async (authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_types', {
        parameters: {
            authorization,
        },
        options: {
            langcode: intl.locale
        },
    });

    return payload; 
}, suppressException)

export const eventHasOpenEventType = createActionThunk('EVENT_HAS_OPEN_EVENT_TYPE', async (authorization, store) => {
    const state = store.getState();
    // const { event: { items, included } } = state.shopReducers;
    const { intl } = state.intl;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)



// @todo: refactor this to proper action
export const productFetch = createActionThunk('PRODUCT_FETCH', async (payload, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const { authorization } = payload;

    return await jsonapiClient(process.env.REACT_APP_API_URL, 'product_fetch', {
        parameters: {
            authorization,
        },
        options: {
            langcode: intl.locale
        },
    });
}, suppressException)



/*
    field_booking_info: {
        value: "2021-04-08T20:01:00+00:00",
        end_value: "2021-04-08T21:01:00+00:00",
        duration: 60,
        rrule: null,
        rrule_index: null,
        timezone: ""
    },
*/
export const notificationFetch = createActionThunk('NOTIFICATION_FETCH', async (payload, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const { authorization } = payload;

    return await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
            includes: null, // inherit incuded from event_fetch main request
            filter: {
                'field_event_type.id': TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID,
                // 'this_name_does_not_matter_its_juts_for_grouping_params_when_filtering': {
                //     condition: {
                //         path: 'field_booking_info.value',
                //         operator: '>',
                //         value: moment().unix(),
                //     }
                // },
                'field_booking_info.value': {
                    operator: '>',
                    value: moment().unix(), // since now, we do not use = maybe this slows down the query and therefore request ???
                    // value:  moment(Date()).format(),
                    // value:  new Date(),
                    // value:  moment(new Date()).toISOString(),
                    // value:  moment().toISOString(),
                    // value:  new Date().toISOString(),
                    // value: `2021-04-02T20:00:00+00:00`,
                    // value: new Date().getTime(),
                },

            },
            // page: {
            //     limit: 50,
            // },
            sort: [
                // 'created', // created field in node event entity
                'field_booking_info.value', // field_booking_info.value field in node event entity
            ],
        },
        options: {
            langcode: intl.locale
        },
    });
}, suppressException)



// @see: https://stackoverflow.com/questions/49909213/how-to-get-the-beginning-and-end-of-the-day-with-moment
// @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
export const todayFetch = createActionThunk('TODAY_FETCH', async (payload, store) => {
    const state = store.getState();
    const { intl } = state.intl;
    
    const { authorization } = payload;

    return await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
        parameters: {
            authorization, 
            includes: null, // inherit incuded from event_fetch main request
            filter: {
                'today_events_from': {
                    path: `created`,
                    operator: '>=',
                    value: moment().startOf('day').unix(),
                },
                'today_events_to': {
                    path: `created`,
                    operator: '<=',
                    value: moment().endOf('day').unix(),
                },
            },
            page: {
                limit: 250,
            },
            sort: [
                '-created', // created field in node event entity
            ],
        },
        options: {
            langcode: intl.locale
        },
    });
}, suppressException)


/**
 * Checks if last day when was OPEN event has CLOSE event
 * @todo rename method from "yesterdayHasCloseEventFetch" to "lastOpenDayHasCloseEventFetch"
 */
// @see: https://stackoverflow.com/questions/49909213/how-to-get-the-beginning-and-end-of-the-day-with-moment
// @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
export const yesterdayHasCloseEventFetch = createActionThunk('YESTERDAY_HAS_CLOSE_EVENT_FETCH', async (payload, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const { authorization } = payload;

    const payload_last_open_event_fetch = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
        parameters: {
            authorization, 
            includes: null, // inherit incuded from event_fetch main request
            filter: {
                'field_event_type.id': TAXONOMY_TERM_EVENT_TYPES_DAY_OPEN_UUID,
                // 'last_open_events_from': {
                //     path: `created`,
                //     operator: '>=',
                //     value: moment().subtract(1, 'days').startOf('day').unix(),
                // },
                'last_open_events_to': {
                    path: `created`,
                    operator: '<=',
                    value: moment().subtract(1, 'days').endOf('day').unix(),
                },
            },
            page: {
                limit: 1,
            },
            sort: [
                '-created', // created field in node event entity
            ],
        },
        options: {
            langcode: intl.locale
        },
    });

    const { data: lastOpenDayEvent, included, jsonapi, links } = payload_last_open_event_fetch;

// console.log(`lastOpenDayEvent`);
// console.log(lastOpenDayEvent);

    const payload_last_close_event_fetch = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
        parameters: {
            authorization, 
            includes: null, // inherit incuded from event_fetch main request
            filter: {
                'field_event_type.id': TAXONOMY_TERM_EVENT_TYPES_DAY_CLOSE_UUID,
                'last_close_events_from': {
                    path: `created`,
                    operator: '>=',
                    value: moment(lastOpenDayEvent?.[0]?.attributes?.created).unix(),
                },
                'last_close_events_to': {
                    path: `created`,
                    operator: '<=',
                    value: moment(lastOpenDayEvent?.[0]?.attributes?.created).endOf('day').unix(),
                },
            },
            page: {
                limit: 1,
            },
            sort: [
                '-created', // created field in node event entity
            ],
        },
        options: {
            langcode: intl.locale
        },
    });

    return { 
        data: payload_last_close_event_fetch.data,
        included: payload_last_close_event_fetch.included,
        lastOpenDayEvent,
    }
}, suppressException)



// @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
export const getCashRegisterAmount = createActionThunk('GET_CASH_REGISTER_AMOUNT', async (payload, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const { authorization } = payload;

    const response = await fetch(`${process.env.SOFT4NET_SOURCE_BASE_URL}/${intl.locale}/jsonapi/commerce_store/online`, {
        method: 'GET',
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${authorization.token_type} ${authorization.access_token}`,
        },
    })

    return await response.json(); // { data, included, ... } 
}, suppressException)



export const resetLastAddedEventUuid = createAction('RESET_LAST_ADDED_EVENT_UUID');
