import { useStaticQuery, graphql } from 'gatsby'

const useAllFile = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile {
                edges {
                    node {
                        relativePath
                        childImageSharp {
                            fluid(
                                # maxWidth: 1920
                                # maxWidth: 340, 
                                srcSetBreakpoints: [ 1200 ]
                            ) {
                                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.

                                # ...GatsbyImageSharpFluid
                                # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                ...GatsbyImageSharpFluid_withWebp

                                # If you don’t want to use the blur-up effect, choose the fragment with noBase64 at the end.
                                # @see: https://www.gatsbyjs.org/packages/gatsby-image/#fragments
                                # ...GatsbyImageSharpFluid_noBase64
                            }
                            # fluid(maxWidth: 100) {
                            #     ...GatsbyImageSharpFluid_withWebp
                            # }
                            # fixed(width: 400, height: 400) {
                            #     ...GatsbyImageSharpFixed
                            # }
                        }
                    }
                }
            }
        }
    `)

    return data
}

export default useAllFile