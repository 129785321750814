import { TOGGLE_POPUP_STATUS } from './../actions/types';

const initialState = {
    isPopupOpened: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_POPUP_STATUS:
            return {
                ...state, 
                isPopupOpened: action.isPopupOpened,
            };
        default:
            return state;
    }
};