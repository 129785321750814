import React, { useState, useEffect } from 'react'
import { jsonapiClient } from 's4n-jsonapi-client' // for events we do not want to user redux, we want to call an API instantly
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import OrderItems from './OrderItems'


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { order, mappedIncludes, tableCellDisplay } = props;

/*
    const [event, setEvent] = useState(null);

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {       

// console.log(order)

        const relationshipFieldOrderRelationshipOrderItems = getRelationshipFromMappedIncludes(order, 'order_items', mappedIncludes);

// console.log(relationshipFieldOrderRelationshipOrderItems)

        setEvent({
            order_items: relationshipFieldOrderRelationshipOrderItems,
        });
    }
*/
    const relationshipFieldOrderRelationshipOrderItems = getRelationshipFromMappedIncludes(order, 'order_items', mappedIncludes);

    if (!relationshipFieldOrderRelationshipOrderItems) {
        return null;
    }

    return (
        <React.Fragment>
            <OrderItems orderItems={relationshipFieldOrderRelationshipOrderItems} mappedIncludes={mappedIncludes} tableCellDisplay={tableCellDisplay} />
        </React.Fragment>
    )
};

export default Component;