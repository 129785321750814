// import 'jquery/dist/jquery'
// import 'jquery/dist/jquery.min.js'

// import 'popper.js/dist/popper.min'
// import 'bootstrap/js/dist/util'
// import 'bootstrap/js/dist/carousel'
// import 'bootstrap/js/dist/dropdown'

// Import all js dependencies from used theme
// import './static/theme/focus/vendor/global/global.min' // jQuery v3.4.1, Bootstrap v4.3.1, perfect-scrollbar v1.4.0, metismenu https://github.com/onokumus/metismenu#readme A jQuery menu plugin v3.0.3, 
// import './static/theme/focus/js/quixnav-init.js'
// import './static/theme/focus/js/custom.min.js'
// import './static/theme/focus/vendor/chartist/js/chartist.min.js'
// import './static/theme/focus/vendor/moment/moment.min.js'
// import './static/theme/focus/vendor/pg-calendar/js/pignose.calendar.min.js'
// import './static/theme/focus/js/dashboard/dashboard-2.js'

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider