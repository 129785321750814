/**
 * Just view/template component, no model/data logic!
 */
import React, { useState } from "react"
import Img from 'gatsby-image'
import ImagePlaceholder from "../../../image"
// import SEO from "./../../seo"

// s4n
import { useIntl } from "gatsby-plugin-intl"

import { ReactSlick as CarouselSlider } from './../../../../hooks/drupal/parseContentProcessor/Carousel/useReactSlick'
// import { Carousel } from 'react-bootstrap' // @see: https://react-bootstrap.github.io/components/carousel/

// @see: https://github.com/frontend-collective/react-image-lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


/**
 * Component displays all main (first of each variant) images for all variants (if any) or placeholder empty image.
 * If article has more than one variant, each main variant image will be displayed as carousel images.
 * 
 * @param { defaultVariation, carousel } props 
 */
const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { defaultVariation: variation, carousel } = props;  
    const isCarousel = carousel || false;

    const [sliderState, setSliderState] = useState({
        slideIndex: 0,
        updateCount: 0,
    });

    // @see: https://github.com/frontend-collective/react-image-lightbox
    const [lightboxState, setLightboxState] = useState({
        photoIndex: 0,
        isOpen: false,
    });

// console.log(props)
// console.log(variation.relationships.field_images);

    if (!(variation && variation.relationships)) {
        return <ImagePlaceholder className={`image-placeholder`} />;
    }

    // const variation = defaultVariation ? defaultVariation.entity : null;


    //variation = variation.entity ? variation.entity : variation;

    let images = [];
    variation.relationships.field_images && variation.relationships.field_images.forEach((field_image, index) => {
        const imageDerivative = field_image && field_image.derivative ? field_image.derivative : null;
        const imageLocalFile = field_image && field_image.localFile ? field_image.localFile : null;
        if (imageDerivative || imageLocalFile) {
            images.push({
                derivative: imageDerivative,
                fluid: imageLocalFile.childImageSharp.fluid,
                // alt: variation.title,
            });
        }
    });

// console.log(images);
    
    if (0 === images.length) {
        return <ImagePlaceholder className={`image-placeholder`} />;
    }

    const hasControls = images.length > 1;

    const carouselSliderSettings = {
        dots: true, // required by customPaging !!!
        dotsClass: `slick-dots slick-thumb`, // required by customPaging !!!

        lazyLoad: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        infinite: true,
        arrows: hasControls,
        // adaptiveHeight: true,
        customPaging: (i) => {
            const image = images[i];

// console.log(image.fluid)

            return (
                <a className={`_col-lg-6 _d-flex`} style={{
                    // overflow: `hidden`, 
                    // height: 0, 
                    // paddingBottom: `100%`
                    // width: `100px`,
                    // height: `100px`,
                    }}>
                    {/* <img src={`${baseUrl}/abstract0${i + 1}.jpg`} /> */}
                    <Img 
                        fluid={image.fluid} alt={image.alt} 
                        // style={{flex: 1, width: `100%`, height: `100%`}}
                        // objectFit="cover" objectPosition="50% 50%" 
                    />
                </a>
            );
        },
        afterChange: (currentSlideIndex) => {
            setLightboxState((prevState, props) => ({
                ...prevState,
                photoIndex: currentSlideIndex,
            }))
        },

    };

    return (
        <React.Fragment>
            {
                !isCarousel ? 
                    <React.Fragment>
                        {images[0].derivative && <img src={images[0].derivative.url} width={images[0].derivative.width} height={images[0].derivative.height} alt={images[0].alt} />}
                        {images[0].fluid && <Img fluid={images[0].fluid} alt={images[0].alt} />}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <CarouselSlider {...carouselSliderSettings}>
                            {images.map((image, index) => {
                                return (
                                    <React.Fragment>
                                        {/* <img
                                            className="d-block w-100"
                                            src="holder.js/800x400?text=First slide&bg=373940"
                                            alt="First slide"
                                        /> */}
                                        <Img fluid={image.fluid} alt={image.alt} />
                                        {/* <Carousel.Caption>
                                            <h3>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption> */}

                                        {/* @see: https://github.com/frontend-collective/react-image-lightbox START */}
                                        {/* <button id="zoomOpen" className="zoom-btn" type="button" onClick={() => setLightboxState((prevState, props) => ({ ...prevState, photoIndex: index, isOpen: true, }))}>
                                            <i className="ti-search"></i>
                                        </button> */}
                                        {/* @see: https://github.com/frontend-collective/react-image-lightbox STOP */}
                                    </React.Fragment>
                                )
                            })}
                        </CarouselSlider>



                        {/* @see: https://github.com/frontend-collective/react-image-lightbox START */}
                        <button id="zoomOpen" className="zoom-btn" type="button" onClick={() => setLightboxState((prevState, props) => ({ ...prevState, isOpen: true, }))}>
                            <i className="ti-search"></i>
                        </button>

                        {lightboxState.isOpen && (
                            <Lightbox
                                mainSrc={images[lightboxState.photoIndex].fluid.src}
                                nextSrc={images[(lightboxState.photoIndex + 1) % images.length].fluid.src}
                                prevSrc={images[(lightboxState.photoIndex + images.length - 1) % images.length].fluid.src}
                                onCloseRequest={() => setLightboxState((prevState, props) => ({ ...prevState, isOpen: false, }))}
                                onMovePrevRequest={() => 
                                    setLightboxState((prevState, props) => ({
                                        ...prevState,
                                        photoIndex: (lightboxState.photoIndex + images.length - 1) % images.length,
                                    }))
                                }
                                onMoveNextRequest={() => 
                                    setLightboxState((prevState, props) => ({
                                        ...prevState,
                                        photoIndex: (lightboxState.photoIndex + 1) % images.length,
                                    }))
                                }
                                // enableZoom={false}
                            />
                        )}
                        {/* @see: https://github.com/frontend-collective/react-image-lightbox STOP */}



                        {/* <Carousel
                            interval={null}
                            // indicators={false}
                            controls={hasControls}
                        >
                            {images.map((image, index) => {
                                return (
                                    <Carousel.Item key={`image_carousel_item_${index}`}>
                                        <Img fluid={ image.fluid } alt={image.alt} />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel> */}

                    </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ComponentStateless
