import { handleActions } from 'redux-actions';

// s4n
// import { LOCATION_CHANGE } from 'react-router-redux'

export default handleActions({
  'CART_ADD_SUCCEEDED': (state) => ({
    ...state,
    open: true,
  }),
  CART_FLYOUT_OPEN: (state) => ({
    ...state,
    open: true,
  }),
  CART_FLYOUT_CLOSE: (state) => ({
    ...state,
    open: false,
  }),
  '@@router/LOCATION_CHANGE': (state) => ({ // THIS IS NOT WORKING, WHY ??? !!!
    ...state,
    open: false,
  }),
}, {
  open: false,
})
