import { handleActions } from 'redux-actions';

import _ from 'lodash' // for selector
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk" // for selector

export default handleActions({
  WISHLIST_FETCH_STARTED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  WISHLIST_FETCH_SUCCEEDED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_SUCCEEDED: ', payload);
    const { wishlist, included } = payload;
    return {
      ...state,
      items: [wishlist],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_wishlist_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  WISHLIST_FETCH_FAILED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_FAILED: ', payload);
    return state;
  },
  WISHLIST_FETCH_ENDED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },

  WISHLIST_ADD_STARTED: (state, { payload }) => {
    return {
      ...state,
      loading: true,
    };
  },
  WISHLIST_ADD_SUCCEEDED: (state, { payload }) => {
    const { wishlist, included } = payload;
    return {
      ...state,
      items: [wishlist],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_wishlist_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  WISHLIST_ADD_ENDED: (state, { payload }) => {
    return {
      ...state,
      loading: false,
    };
  },
  WISHLIST_ADD_FAILED: (state, { payload }) => {
    // @todo get the error from the error payload.
    console.log('Sorry, the product could not be added to the wishlist.')
    return state;
  },

  WISHLIST_UPDATE_ITEM_STARTED: state => {
    return {
      ...state,
      loading: true,
    }
  },
  WISHLIST_UPDATE_ITEM_SUCCEEDED: (state, { payload }) => {
    const { wishlist, included } = payload;
    return {
      ...state,
      items: [wishlist],
      included,
      itemCount: payload.included ? payload.included.filter(item => item.type.indexOf('commerce_wishlist_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  WISHLIST_UPDATE_ITEM_ENDED: (state, { payload }) => {
    return {
      ...state,
      loading: false,
    };
  },
  WISHLIST_UPDATE_ITEM_FAILED: (state, { payload }) => {
    // console.log(payload);
    return state;
  },
}, {
  loading: false,
  items: [],
  included: [],
  itemCount: 0,
  errorMessage: null,
})
